import HomeLayout from "@layouts/HomeLayout";
import { Col, Row, Typography } from "antd";
import CardDesign from "./components/card-design";
import "./index.scss";
import { useNavigation } from "@context/navigation-provider";
import { useNavigate } from "react-router-dom";
import LogoTextComponent from "@layouts/components/Sidemenu/components/LogoTextComponent";
const { Title, Text } = Typography;

function HomePage(props: any) {
  const { userInfo } = useNavigation();
  const navigate = useNavigate();
  return (
    <HomeLayout>
      <Title
        level={3}
        className="text-center mb-4 d-flex justify-content-center"
      >
        Welcome to{" "}
        <div className="mx-2">
          <LogoTextComponent />
        </div>
      </Title>
      <div className="d-flex justify-content-center gap-3">
        <CardDesign type={"personal"} userInfo={userInfo} />
        <CardDesign type={"business"} userInfo={userInfo} />
      </div>
    </HomeLayout>
  );
}

export default HomePage;
