import { patch, post, postWithMethod } from "./helpers/http-handler";

//sign-up
export const authRegister = (payload: object) =>
  post(`${process.env.REACT_APP_API_URL}/auth/register`, payload);

export const authLogin = (payload: object) =>
  post(`${process.env.REACT_APP_API_URL}/auth/login`, payload);

export const authForgotPassword = (payload: object) =>
  post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, payload);

export const authSetPassword = (payload: object) =>
  post(`${process.env.REACT_APP_API_URL}/auth/set-password`, payload);
