import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ErrorBoundary";
import { NavigationProvider } from "@context/navigation-provider";
import "@scss/common/base.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-international-phone/style.css';


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
/* -------------------------------------------------------------------------- */
/*                               UseState Section                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               React Query Section                          */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               API Section                                  */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               Onchange section                             */
/* -------------------------------------------------------------------------- */

root.render(
  <NavigationProvider>
    {/* <React.StrictMode> */}
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    {/* </React.StrictMode> */}
  </NavigationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
