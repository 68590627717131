import { getValue } from "@utils/lodash";
import React from "react";

function isVideo(url: string) {
  return url && (url.endsWith(".mp4") || url.includes("video"));
}

function CardPreviewHeader3(props: any) {
  const { request } = props;
  const bannerURL = getValue(request, "userInfo.profile_logo[0].url", "");
  const imageUrl = getValue(request, "aboutInfo.business_logo[0].url", "");

  return (
    <div className="position-relative">
      <div
        className="card_preview_header_container__3"
        style={{
          background: getValue(request, `settings.theme_color`, ""),
        }}
      >
        <div className="card_avatar__3">
          {bannerURL && (
            <>
              {isVideo(bannerURL) ? (
                <video
                  className="card_preview_header_banner"
                  src={bannerURL}
                  controls
                  autoPlay
                  loop
                  muted
                />
              ) : (
                <img
                  src={bannerURL}
                  alt="Profile Logo"
                  className="card_preview_header_banner"
                />
              )}
            </>
          )}
        </div>

        {/* <div className="card_preview_header_shape_container">
          {imageUrl && (
            <div className="card_preview_header_logo">
              {isVideo(imageUrl) ? (
                <video
                  src={imageUrl}
                  controls
                  className="card_preview_header_logo_video"
                />
              ) : (
                <img src={imageUrl} alt="Business Logo" />
              )}
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default CardPreviewHeader3;
