import { useEffect } from "react";
import { getValue } from "@utils/lodash";
import { Form, Input, Typography } from "antd";
import { PhoneInput } from "react-international-phone";
import MultipleUploadComponent from "@components/common/multiple-upload";
import SingleUploadComponent from "@components/common/single-upload";

function BankDetailsPage(props: any) {
  const { Title } = Typography;
  const { request, setRequest } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      bank_name: getValue(request, `bankDetails.bank_name`, ""),
      account_holder_name: getValue(request, `bankDetails.account_holder_name`, ""),
      account_number: getValue(request, `bankDetails.account_number`, ""),
      account_type: getValue(request, `bankDetails.account_type`, ""),
      branch_name: getValue(request, `bankDetails.branch_name`, ""),
      ifsc_code: getValue(request, `bankDetails.ifsc_code`, ""),
      iban_number: getValue(request, `bankDetails.iban_number`, ""),
      swift_code: getValue(request, `bankDetails.swift_code`, ""),
    });
  }, [request, form]);

  const handleChange = (e: any, key: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [key]: {
        ...request[key],
        [name]: value,
      },
    });
  };

  const handleUploadMultiLogos = (e: any, key: string) => {
    request.bankDetails[key].push(e);
    setRequest({ ...request });
  };

  const handleRemoveMultiLogos = (e: any, key: string) => {
    setRequest({
      ...request,
      bankDetails: {
        ...request.bankDetails,
        [key]: e,
      },
    });
  };

  return (
    <Form
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      layout="vertical"
    >
      <Form.Item label="Bank Name">
        <Input
          size="large"
          name="bank_name"
          value={getValue(request, `bankDetails.bank_name`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter Bank Name"
        />
      </Form.Item>
      <Form.Item label="Account Holder Name">
        <Input
          size="large"
          name="account_holder_name"
          value={getValue(request, `bankDetails.account_holder_name`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter Account Holder Name"
        />
      </Form.Item>
      <Form.Item label="Account Number">
        <Input
          size="large"
          name="account_number"
          value={getValue(request, `bankDetails.account_number`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter Account Number"
        />
      </Form.Item>
      <Form.Item label="Account Type">
        <Input
          size="large"
          name="account_type"
          value={getValue(request, `bankDetails.account_type`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter Account Type"
        />
      </Form.Item>
      <Form.Item label="Branch Name">
        <Input
          size="large"
          name="branch_name"
          value={getValue(request, `bankDetails.branch_name`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter Branch Name"
        />
      </Form.Item>
      <Form.Item label="IFSC Code">
        <Input
          size="large"
          name="ifsc_code"
          value={getValue(request, `bankDetails.ifsc_code`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter IFSC Code"
        />
      </Form.Item>
      <Form.Item label="IBAN Number">
        <Input
          size="large"
          name="iban_number"
          value={getValue(request, `bankDetails.iban_number`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter IBAN Number"
        />
      </Form.Item>
      <Form.Item label="Swift Code">
        <Input
          size="large"
          name="swift_code"
          value={getValue(request, `bankDetails.swift_code`, "")}
          onChange={(e: any) => handleChange(e, "bankDetails")}
          placeholder="Enter Swift Code"
        />
      </Form.Item>
      <Form.Item label="PhonePay Number">
        <PhoneInput
          placeholder="Enter number"
          value={getValue(request, `bankDetails.phonepay_number`, "")}
          defaultCountry="in"
          onChange={(e: any) =>
            setRequest({
              ...request,
              bankDetails: {
                ...request.bankDetails,
                phonepay_number: e,
              },
            })
          }
        />
      </Form.Item>
      <Form.Item label="GooglePay Number">
        <PhoneInput
          placeholder="Enter  number"
          value={getValue(request, `bankDetails.googlepay_number`, "")}
          defaultCountry="in"
          onChange={(e: any) =>
            setRequest({
              ...request,
              bankDetails: {
                ...request.bankDetails,
                googlepay_number: e,
              },
            })
          }
        />
      </Form.Item>
      <Form.Item label="Paytm Number">
        <PhoneInput
          placeholder="Enter number"
          value={getValue(request, `bankDetails.paytm_number`, "")}
          defaultCountry="in"
          onChange={(e: any) =>
            setRequest({
              ...request,
              bankDetails: {
                ...request.bankDetails,
                paytm_number: e,
              },
            })
          }
        />
      </Form.Item>
      <div className="mt-3">
        <Title level={5}>PhonePay QR Images</Title>
        <SingleUploadComponent
          title={""}
          imageUrls={getValue(request, `bankDetails.phone_pay_qrimages`, [])}
          uploadSuccess={(e: any) =>
            handleUploadMultiLogos(e, "phone_pay_qrimages")
          }
          uploadRemove={(e: any) =>
            handleRemoveMultiLogos(e, "phone_pay_qrimages")
          }
        />
      </div>
      <div className="mt-3">
        <Title level={5}>GooglePay QR Images</Title>
        <SingleUploadComponent
          title={""}
          imageUrls={getValue(request, `bankDetails.google_pay_qrimages`, [])}
          uploadSuccess={(e: any) =>
            handleUploadMultiLogos(e, "google_pay_qrimages")
          }
          uploadRemove={(e: any) =>
            handleRemoveMultiLogos(e, "google_pay_qrimages")
          }
        />
      </div>
      <div className="mt-3">
        <Title level={5}>Paytm QR Images</Title>
        <SingleUploadComponent
          title={""}
          imageUrls={getValue(request, `bankDetails.paytm_qrimages`, [])}
          uploadSuccess={(e: any) =>
            handleUploadMultiLogos(e, "paytm_qrimages")
          }
          uploadRemove={(e: any) =>
            handleRemoveMultiLogos(e, "paytm_qrimages")
          }
        />
      </div>
    </Form>
  );
}

export default BankDetailsPage;
