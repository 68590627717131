import React from "react";

function LogoTextComponent(props: any) {
  return (
    <h6
      className="header_text__22 mt-1 fw-bold"
      style={{
        fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
      }}
    >
      <span style={{ color: "#38629A" }}>Zippy</span>
      <span style={{ color: "#F7A31C" }}>Cards</span>
    </h6>
  );
}

export default LogoTextComponent;
