import { AnalyticsRoutes } from "./private/analytics.routes";
import { CardsRoutes } from "./private/cards.routes";
import { ContactsRoutes } from "./private/contacts.routes";
import { DashboardRoutes } from "./private/dashboard.routes";
import { DesignRoutes } from "./private/design.routes";
import { EnquiryRoutes } from "./private/enquiries.routes";
import { PricingRoutes } from "./private/pricing.routes";
import { SettingsRoutes } from "./private/settings.routes";
import { UsersRoutes } from "./private/users.routes";

export const PrivateRoutes = [
  ...DashboardRoutes,
  ...CardsRoutes,
  ...ContactsRoutes,
  ...DesignRoutes,
  ...PricingRoutes,
  ...EnquiryRoutes,
  ...AnalyticsRoutes,
  ...SettingsRoutes,
  ...UsersRoutes
];
