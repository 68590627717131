import { getValue } from "@utils/lodash";
import Lottie from "lottie-react";
import React from "react";
import LoaderAnimation from "@components/lottie/loader.json";

function Loader(props: any) {
  return (
    <div
      className="w-100"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <Lottie
          animationData={LoaderAnimation}
          loop={true}
          style={{ width: "300px" }}
        />
      </div>
    </div>
  );
}

export default Loader;
