import React from "react";
import { getValue } from "@utils/lodash";

function CardTiktok(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          clipRule="evenodd"
          d="M15.2585 3C15.5561 5.55428 16.9848 7.07713 19.4688 7.23914V10.112C18.0292 10.2524 16.7683 9.78262 15.3018 8.89699V14.2702C15.3018 21.096 7.84449 23.2291 4.84643 18.3365C2.91988 15.1882 4.09962 9.66382 10.2797 9.44241V12.4719C9.80893 12.5475 9.30564 12.6663 8.84565 12.8229C7.47109 13.2873 6.69181 14.1568 6.90827 15.6904C7.32497 18.6281 12.7258 19.4975 12.2766 13.7571V3.0054H15.2585V3Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default CardTiktok;
