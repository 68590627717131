import EnquiriesPage from "@pages/private/Enquiries";
import UsersPage from "@pages/private/Users";

export const UsersRoutes = [
  {
    path: "/users",
    name: "enquiry",
    component: UsersPage,
  },
];
