import MultipleUploadComponent from "@components/common/multiple-upload";
import { getValue } from "@utils/lodash";
import { Col, Form, Input, Modal, Select } from "antd";
import { useEffect } from "react";

const { Option } = Select;

const ProductServiceModal = (props: any) => {
  const { open, onClose, request, setRequest, handleSubmit } = props;
  const [form] = Form.useForm(); // Create a form instance

  const handleOk = () => {
    form.validateFields().then(() => {
      handleSubmit();
    });
  };

  // Update form fields when request changes
  useEffect(() => {
    form.setFieldsValue({
      type: getValue(request, `ps_type`, ""),
      name: getValue(request, `ps_name`, ""),
      price: getValue(request, `ps_price`, "0"),
    });
  }, [request, form]);

  const handleUploadMultiLogos = (e: any) => {
    console.log(e);
    request.ps_images.push(e);
    setRequest({ ...request });
  };

  const handleRemoveMultiLogos = (e: any) => {
    setRequest({
      ...request,
      ps_images: e,
    });
  };

  return (
    <Modal
      title="Products/Services"
      open={open}
      onOk={handleOk}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          type: getValue(request, `ps_type`, ""),
          name: getValue(request, `ps_name`, ""),
          price: getValue(request, `ps_price`, "0"),
        }}
        className="product_form"
      >
        <Col>
          <Form.Item name="type" label="Type">
            <Select
              placeholder="Please select type"
              size="large"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  ps_type: e,
                })
              }
            >
              <Option value="product">Product</Option>
              <Option value="service">Service</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="name" label="Name">
            <Input
              placeholder="Please enter name"
              size="large"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  ps_name: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="price" label="Price(INR)">
            <Input
              placeholder="Please enter Price"
              size="large"
              type="number"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  ps_price: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Form>
      <MultipleUploadComponent
        title={"Images"}
        imageUrls={
          getValue(request, `ps_images.length`, 0) > 0
            ? getValue(request, `ps_images`, [])
            : []
        }
        uploadSuccess={handleUploadMultiLogos}
        uploadRemove={handleRemoveMultiLogos}
      />
    </Modal>
  );
};

export default ProductServiceModal;
