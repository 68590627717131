import CardsPage from "@pages/private/Cards";
import CreateBusinessCardPage from "@pages/private/Home/CreateCard/business";
import CreatePersonalCardPage from "@pages/private/Home/CreateCard/personal";
import EditCardsPage from "@pages/private/Home/EditCard";

export const CardsRoutes = [
  {
    path: "/cards",
    name: "Cards",
    component: CardsPage,
  },
  {
    path: "/create-card/personal",
    name: "Cards",
    component: CreatePersonalCardPage,
  },
  {
    path: "/create-card/business",
    name: "Cards",
    component: CreateBusinessCardPage,
  },
  {
    path: "/create-card/personal/:id",
    name: "Cards",
    component: CreatePersonalCardPage,
  },
  {
    path: "/create-card/business/:id",
    name: "Cards",
    component: CreateBusinessCardPage,
  },
  {
    path: "/edit-card/:type",
    name: "Cards",
    component: EditCardsPage,
  },
];
