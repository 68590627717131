import React from "react";
import { getValue } from "@utils/lodash";

function CardWhatsappIcon(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          d="M16.75 13.96C17 14.09 17.16 14.16 17.21 14.26C17.27 14.37 17.25 14.87 17 15.44C16.8 16 15.76 16.54 15.3 16.56C14.84 16.58 14.83 16.92 12.34 15.83C9.85 14.74 8.35 12.08 8.23 11.91C8.11 11.74 7.27 10.53 7.31 9.3C7.36 8.08 8 7.5 8.26 7.26C8.5 7 8.77 6.97 8.94 7H9.41C9.56 7 9.77 6.94 9.96 7.45L10.65 9.32C10.71 9.45 10.75 9.6 10.66 9.76L10.39 10.17L10 10.59C9.88 10.71 9.74 10.84 9.88 11.09C10 11.35 10.5 12.18 11.2 12.87C12.11 13.75 12.91 14.04 13.15 14.17C13.39 14.31 13.54 14.29 13.69 14.13L14.5 13.19C14.69 12.94 14.85 13 15.08 13.08L16.75 13.96ZM12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.03 22 8.2 21.43 6.65 20.45L2 22L3.55 17.35C2.57 15.8 2 13.97 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2V2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 13.72 4.54 15.31 5.46 16.61L4.5 19.5L7.39 18.54C8.69 19.46 10.28 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4V4Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
        ></path>
      </svg>
    </div>
  );
}

export default CardWhatsappIcon;
