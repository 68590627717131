import { getCardIconComponent } from "@common/card-icons-helper";
import { getValue } from "@utils/lodash";
import { Badge, Image } from "antd";
import React from "react";
import BankCard from "../helpers/bank-card";
import PersonSvgComponent from "@assets/svg/person";
import BrandingSvgComponent from "@assets/svg/branding";
import { NumberOutlined } from "@ant-design/icons";
import GpaySvgIcon from "@assets/svg/gpay";
import PhonepaySvgComponent from "@assets/svg/phonepay";
import PaytmSvgComponent from "@assets/svg/paytm";

function CardPreviewBankDetails(props: any) {
  const { request } = props;
  return (
    <>
      {(getValue(request, `bankDetails.account_holder_name`, "") ||
        getValue(request, `bankDetails.account_number`, "") ||
        getValue(request, `bankDetails.ifsc_code`, "") ||
        getValue(request, `bankDetails.bank_name`, "") ||
        getValue(request, `bankDetails.branch_name`, "") ||
        getValue(request, `bankDetails.phonepay_number`, "") ||
        getValue(request, `bankDetails.googlepay_number`, "") ||
        getValue(request, `bankDetails.paytm_number`, "")) && (
        <h6 className="header_text__22 text-center mt-4 mb-3 color_desc">
          Bank Information
        </h6>
      )}
      <div className="d-flex  flex-column mb-4">
        {getValue(request, `bankDetails.account_holder_name`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.account_holder_name`, "")}
            icon={<PersonSvgComponent color={"#fff"} />}
          />
        )}
        {getValue(request, `bankDetails.account_number`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.account_number`, "")}
            icon={<NumberOutlined style={{ color: "#fff" }} />}
          />
        )}
        {getValue(request, `settings.ifsc_code`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.ifsc_code`, "")}
            icon={<NumberOutlined style={{ color: "#fff" }} />}
          />
        )}
        {getValue(request, `bankDetails.bank_name`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.bank_name`, "")}
            icon={<BrandingSvgComponent color={"#fff"} />}
          />
        )}
        {getValue(request, `bankDetails.branch_name`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.branch_name`, "")}
            icon={<BrandingSvgComponent color={"#fff"} />}
          />
        )}
        {getValue(request, `bankDetails.phonepay_number`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.phonepay_number`, "")}
            icon={<PhonepaySvgComponent color={"#fff"} />}
          />
        )}
        {getValue(request, `bankDetails.googlepay_number`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.googlepay_number`, "")}
            icon={<GpaySvgIcon color={"#fff"} />}
          />
        )}
        {getValue(request, `bankDetails.paytm_number`, "") && (
          <BankCard
            theme_color={getValue(request, `settings.theme_color`, "")}
            value={getValue(request, `bankDetails.paytm_number`, "")}
            icon={<PaytmSvgComponent color={"#fff"} />}
          />
        )}
      </div>
      <div className="d-flex flex-wrap gap-3 ">
        {getValue(request, `bankDetails.phone_pay_qrimages[${0}].url`, "") && (
          <Badge.Ribbon text={"PhonePay"} color={"#5f249f"}>
            <Image
              src={getValue(
                request,
                `bankDetails.phone_pay_qrimages[${0}].url`,
                ""
              )} // Display the image thumbnail
              alt={`Image1 `}
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
                cursor: "pointer",
                border: "1px solid #efefef",
                borderRadius: "5px",
              }}
            />
          </Badge.Ribbon>
        )}
        {getValue(request, `bankDetails.google_pay_qrimages[${0}].url`, "") && (
          <Badge.Ribbon text={"GooglePay"} color={"#3481ee"}>
            <Image
              src={getValue(
                request,
                `bankDetails.google_pay_qrimages[${0}].url`,
                ""
              )} // Display the image thumbnail
              alt={`Image1 `}
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
                cursor: "pointer",
                border: "1px solid #efefef",
                borderRadius: "5px",
              }}
            />
          </Badge.Ribbon>
        )}
        {getValue(request, `bankDetails.paytm_qrimages[${0}].url`, "") && (
          <Badge.Ribbon text={"Paytm"} color={"#052c6b"}>
            <Image
              src={getValue(
                request,
                `bankDetails.paytm_qrimages[${0}].url`,
                ""
              )} // Display the image thumbnail
              alt={`Image1 `}
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
                cursor: "pointer",
                border: "1px solid #efefef",
                borderRadius: "5px",
              }}
            />
          </Badge.Ribbon>
        )}
      </div>
    </>
  );
}

export default CardPreviewBankDetails;
