import { Button } from "antd";
import { Header } from "antd/es/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import ProfilePage from "../ProfileIcon";
import AnchorLink from "antd/es/anchor/AnchorLink";
import { useNavigation } from "@context/navigation-provider";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
function TopHeader(props: any) {
  const { collapsed, setCollapsed } = props;
  const { userInfo, remainingDays } = useNavigation();
  return (
    <Header
      style={{ padding: 0, background: "white" }}
      className="d-flex justify-content-between align-items-center"
    >
      <Button
        type="text"
        // icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        icon={collapsed ? <MenuOutlined /> : <MenuOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      />
      <div className="d-flex align-items-center">
        {getValue(userInfo, `is_paid`, "") ? (
          <h6 className="header_text__14 mt-1 d-flex align-items-center">
            <span className="mx-1">
              You are in{" "}
              <span className="color_primary">
                {formatString(getValue(userInfo, `subscription_plan`, ""))}
              </span>{" "}
              plan.
            </span>{" "}
          </h6>
        ) : (
          <h6 className="header_text__14 mt-1 d-flex align-items-center">
            {remainingDays ? (
              <div className="d-flex align-items-center">
                <span className="mx-1">
                  {remainingDays} days left in your Premier trial
                </span>
                <AnchorLink title="Upgrade" href="/subscriptions" />
              </div>
            ) : (
              <>
                <span className="mx-2">
                  Your trial period has ended. Please
                </span>
                <AnchorLink title="Upgrade" href="/subscriptions" />
              </>
            )}

            {/* <span className="mx-1">or</span>
            <AnchorLink title="Extend trail" href="/subscriptions" /> */}
          </h6>
        )}
        <ProfilePage />
      </div>
    </Header>
  );
}

export default TopHeader;
