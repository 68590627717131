import { get, post, patch, Delete } from "./helpers/http-handler";

export const getAllPlans = (queryRequest: any) =>
  get(`${process.env.REACT_APP_API_URL}/plans?${queryRequest}`);

export const getAllSubscriptions = (queryRequest: any) =>
  get(`${process.env.REACT_APP_API_URL}/subscription?${queryRequest}`);

export const getSpecificSubscription = (id: string) =>
  get(`${process.env.REACT_APP_API_URL}/subscription/${id}`);

export const createSubscription = (payload: any) =>
  post(`${process.env.REACT_APP_API_URL}/subscription`, payload);

export const updateSubscription = (payload: any) =>
  post(`${process.env.REACT_APP_API_URL}/subscription/verify`, payload);

export const deleteSubscription = (id: string) =>
  Delete(`${process.env.REACT_APP_API_URL}/subscription/${id}`);
