import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import { getValue } from "@utils/lodash";

const CardUserDescription = (props: any) => {
  return (
    <>
      <h6 className="header_text__22 text-center mt-4 mb-3 color_desc">
        About
      </h6>
      <div className="user_description_container">
        <p
          className="small_text__16"
          dangerouslySetInnerHTML={{
            __html: replaceDefaultPlaceholders(getValue(props, `text`, "")),
          }}
        ></p>
      </div>
    </>
  );
};

export default CardUserDescription;
