import React, { useState } from "react";
import { Layout, theme } from "antd";
import "./layout.scss";
import SideMenu from "./components/Sidemenu";
import TopHeader from "./components/Header";
import { Footer } from "antd/es/layout/layout";

const { Content } = Layout;

const HomeLayout = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div className="d-flex">
      <SideMenu collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <TopHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            margin: "16px",
            padding: 24,

            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
          className="layout_container"
        >
          {children}
        </Content>
      </Layout>
    </div>
  );
};

export default HomeLayout;
