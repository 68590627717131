import AnalyticsPage from "@pages/private/Analytics";
import CardsPage from "@pages/private/Home";

export const AnalyticsRoutes = [
  {
    path: "/analytics",
    name: "Analytics",
    component: AnalyticsPage,
  },
];
