import { getValue } from "@utils/lodash";
import { MessageOutlined } from "@ant-design/icons";
function CardPreviewTitleHeader(props: any) {
  const { request } = props;
  return (
    <>
      <div className="identity">
        {getValue(request, `userInfo.user_name`, "") && (
          <h6 className="header">
            {getValue(request, `userInfo.user_name`, "")}
            {getValue(request, `userInfo.maiden_name`, "") && (
              <span className="ms-2">
                ({getValue(request, `userInfo.maiden_name`, "")})
              </span>
            )}
          </h6>
        )}
        <p className="small_text__16">
          {getValue(request, `userInfo.accreditations`, "")}
        </p>
      </div>
      {(getValue(request, `userInfo.prefered_name`, "") ||
        getValue(request, `userInfo.pronouns`, "")) && (
        <div className="card_preview_preferred_container">
          <MessageOutlined />
          <p className="text mt-3">
            Goes by {getValue(request, `userInfo.prefered_name`, "")}{" "}
            {getValue(request, `userInfo.pronouns`, "") && (
              <span className="ms-1">
                ({getValue(request, `userInfo.pronouns`, "")})
              </span>
            )}
          </p>
        </div>
      )}
    </>
  );
}

export default CardPreviewTitleHeader;
