import { getValue } from "@utils/lodash";
import React from "react";

function isVideo(url: string) {
  return url && (url.endsWith(".mp4") || url.includes("video"));
}

function CardPreviewHeader2(props: any) {
  const { request } = props;
  const bannerURL = getValue(request, "userInfo.profile_logo[0].url", "");
  const imageUrl = getValue(request, "aboutInfo.business_logo[0].url", "");

  return (
    <div className="position-relative">
      <div className="card_preview_header_container">
        {bannerURL && (
          <>
            {isVideo(bannerURL) ? (
              <video
                className="card_preview_header_banner"
                src={bannerURL}
                controls
                autoPlay
                loop
                muted
              />
            ) : (
              <img
                src={bannerURL}
                alt="Profile Logo"
                className="card_preview_header_banner"
              />
            )}
          </>
        )}

        <div className="card_preview_header_shape_container">
          {/* <Shape1 color={getValue(request, `settings.theme_color`, "")} /> */}
          <div
            className="shape_container__2"
            style={{
              background: getValue(request, `settings.theme_color`, ""),
            }}
          ></div>
          {imageUrl && (
            <div className="card_preview_header_logo">
              {isVideo(imageUrl) ? (
                <video
                  src={imageUrl}
                  controls
                  className="card_preview_header_logo_video"
                />
              ) : (
                <img src={imageUrl} alt="Business Logo" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardPreviewHeader2;
