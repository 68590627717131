export const personalIntialState = {
  userInfo: {
    user_name: "",
    user_designation: "",
    contact_number: "",
    whatsapp_number: "",
    user_email: "",
    user_address: "",
    user_info: "",
    accreditations: "",
    maiden_name: "",
    prefered_name: "",
    pronouns: "",
    profile_logo: [],
  },
  aboutInfo: {
    business_logo: [],
    business_name: "",
    website_url: "",
    nature_of_Business: "",
    year_of_establishment: 2024,
    gstin_no: "",
    google_maps_link: "",
    badge_logos: [],
    documents: [],
  },
  socialMediaLinks: [],
  settings: {
    view_count: 0,
    theme_color: "#38629A",
    show_aboutus_section: true,
    show_product_service_section: true,
    show_payment_section: true,
    show_gallery_section: true,
    show_video_section: true,
    show_enquiry_section: true,
    show_maps_section: true,
  },
  is_active: true,
  card_design: "design_1",
  card_type: "personal",
};

export const bussinesIntialState = {
  userInfo: {
    user_name: "",
    user_designation: "",
    contact_number: "",
    whatsapp_number: "",
    user_email: "",
    user_address: "",
    user_info: "",
    accreditations: "",
    maiden_name: "",
    prefered_name: "",
    pronouns: "",
    profile_logo: [],
  },
  aboutInfo: {
    business_logo: [],
    business_name: "",
    website_url: "",
    nature_of_Business: "",
    year_of_establishment: 2024,
    gstin_no: "",
    google_maps_link: "",
    badge_logos: [],
    documents: [],
  },
  socialMediaLinks: [],
  settings: {
    view_count: 0,
    theme_color: "#38629A",
    show_aboutus_section: true,
    show_product_service_section: true,
    show_payment_section: true,
    show_gallery_section: true,
    show_video_section: true,
    show_enquiry_section: true,
    show_maps_section: true,
  },
  is_active: true,
  card_design: "design_1",
  card_type: "business",
};

const exampleintialState = {
  userInfo: {
    user_name: "Sethu Kumar",
    user_designation: "Developer",
    contact_number: "+918887776666",
    whatsapp_number: "+918887776666",
    user_email: "jhon@inboxkitten.com",
    user_address: "hyderabad",
    accreditations: "developer",
    maiden_name: "sk",
    prefered_name: "sethu",
    pronouns: "he/him/his",
    profile_logo: [
      {
        url: "https://tripex-data-public.s3.ap-south-1.amazonaws.com/images/Business.png",
      },
    ],
  },
  aboutInfo: {
    business_logo: [
      {
        url: "https://tripex-data-public.s3.ap-south-1.amazonaws.com/images/SK.png",
      },
    ],
    business_name: "Web Works",
    website_url: "http://zippycards.com/",
    nature_of_Business: "IT",
    year_of_establishment: 2024,
    gstin_no: "JHJH68",
    google_maps_link: "",
    badge_logos: [
      {
        url: "https://tripex-data-public.s3.ap-south-1.amazonaws.com/images/SK%20copy.png",
      },
      {
        url: "https://tripex-data-public.s3.ap-south-1.amazonaws.com/images/SK.png",
      },
      {
        url: "https://tripex-data-public.s3.ap-south-1.amazonaws.com/images/Business.png",
      },
    ],
    documents: [
      {
        url: "https://tripex-data-public.s3.ap-south-1.amazonaws.com/images/Business.png",
        description: "bank",
      },
    ],
  },
  socialMediaLinks: [
    {
      display_label: "Facebook",
      value: "Facebook",
      URL: "https://facebook.com/example",
    },
    {
      display_label: "Instagram",
      value: "Instagram",
      URL: "https://instagram.com/example",
    },
    {
      display_label: "X",
      value: "x",
      URL: "https://twitter.com/example",
    },
    {
      display_label: "YouTube",
      value: "YouTube",
      URL: "https://youtube.com/example",
    },
    {
      display_label: "LinkedIn",
      value: "LinkedIn",
      URL: "https://linkedin.com/example",
    },
    {
      display_label: "Pinterest",
      value: "Pinterest",
      URL: "https://pinterest.com/example",
    },
    {
      display_label: "Website",
      value: "Website",
      URL: "https://example.com",
    },
    {
      display_label: "Discord",
      value: "Discord",
      URL: "https://discord.com/invite/example",
    },
    {
      display_label: "Line",
      value: "Line",
      URL: "https://line.me/R/ti/p/example",
    },
    {
      display_label: "Signal",
      value: "Signal",
      URL: "https://signal.me/#p/+1234567890",
    },
    {
      display_label: "WeChat",
      value: "WeChat",
      URL: "https://example.com/wechat/example",
    },
    {
      display_label: "Telegram",
      value: "Telegram",
      URL: "https://t.me/example",
    },
    {
      display_label: "GitHub",
      value: "GitHub",
      URL: "https://github.com/example",
    },
    {
      display_label: "PDF",
      value: "PDF",
      URL: "https://example.com/documents/example.pdf",
    },
    {
      display_label: "Address",
      value: "Address",
      URL: "https://maps.google.com/?q=123+Main+Street,+Anytown,+USA",
    },
  ],
  settings: {
    view_count: 0,
    theme_color: "#38629A",
  },
  is_active: true,
  card_design: "design_1",
  card_type: "personal",
};
