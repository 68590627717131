import * as React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { getValue } from "@utils/lodash";
import { addEnquiry } from "@services/enquiry.service";
import { PhoneInput } from "react-international-phone";

const EnquiryModal = (props: any) => {
  const { handleModal, request, setRequest } = props;

  const [form] = Form.useForm();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const handleFormSubmit = async () => {
    form
      .validateFields()
      .then(async (values) => {
        // Form is valid, handle submission
        console.log(request);
        setSubmitLoading(true);
        try {
          let resp = await addEnquiry(values);
          if (resp) {
            message.success("Submitted successfully");
            form.resetFields();
            setRequest({
              ...request,
              full_name: "",
              email: "",
              phone: "",
              message: "",
            });
            setSubmitLoading(false);
            handleModal();
          } else {
            setSubmitLoading(false);
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      })
      .catch((info) => {
        // Form has errors, do nothing or handle errors
        console.log("Validation Failed:", info);
      });
  };

  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={handleModal}
      footer={[
        <Button key="cancel" onClick={handleModal} className="cancel__button">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleFormSubmit}
          className="submit__button"
          loading={submitLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <h6 className="header_text__20 mb-5 mt-3">Enquiry Form</h6>
      <Form form={form} layout="vertical" initialValues={request}>
        <Form.Item
          label="Full Name"
          name="full_name"
          rules={[{ required: true, message: "Please enter your full name" }]}
        >
          <Input
            name="full_name"
            value={request.full_name}
            onChange={handleInputChange}
            size="large"
            placeholder="Enter fullname"
          />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please enter your email" }]}
        >
          <Input
            name="email"
            value={request.email}
            onChange={handleInputChange}
            size="large"
            placeholder="Enter email"
          />
        </Form.Item>

        <Form.Item label="Phone" name="phone">
          {/* <Input
              name="phone"
              value={request.phone}
              onChange={handleInputChange}
              size="large"
              placeholder="Enter phone"
            /> */}
          <PhoneInput
            placeholder="Enter phone"
            value={getValue(request, `phone`, "")}
            defaultCountry="in"
            onChange={(e: any) =>
              setRequest({
                ...request,
                phone: e,
              })
            }
          />
        </Form.Item>

        <Form.Item label="Message" name="message">
          <Input.TextArea
            name="message"
            value={request.message}
            onChange={handleInputChange}
            size="large"
            placeholder="Enter message"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EnquiryModal;
