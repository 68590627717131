import { Form, Input } from "antd";
import { getValue } from "@utils/lodash";
import { getCardIconComponent } from "@common/card-icons-helper";
import { handleTextArrayChange } from "@common/handleChange";
import { CloseCircleOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useDraggableInPortal from "@common/useDraggableInPortal";
import { formatString } from "@common/text-helpers";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";

function SocialMediaPage(props: any) {
  const { request, setRequest } = props;
  const handleSelectURL = (value: string) => {
    const socialMediaLinks = getValue(request, "socialMediaLinks", []);
    const index = socialMediaLinks.findIndex(
      (item: any) => getValue(item, "value", "") === value
    );

    const updatedLinks =
      index >= 0
        ? socialMediaLinks.filter((_: any, i: any) => i !== index)
        : [
            ...socialMediaLinks,
            {
              display_label:
                formatString(defaultData[value]?.display_label) || "",
              URL: defaultData[value]?.url || "",
              value: value,
            },
          ];

    setRequest((prevState: any) => ({
      ...prevState,
      socialMediaLinks: updatedLinks,
    }));
  };

  const defaultData: any = {
    Website: { display_label: "Website", url: "https://yourwebsite.com" },
    Link: { display_label: "Link", url: "https://example.com" },
    Instagram: {
      display_label: "Instagram",
      url: "https://instagram.com/yourprofile",
    },
    Email: { display_label: "Email", url: "" },
    LinkedIn: {
      display_label: "LinkedIn",
      url: "https://linkedin.com/in/yourprofile",
    },
    Facebook: {
      display_label: "Facebook",
      url: "https://facebook.com/yourprofile",
    },
    X: { display_label: "X (Twitter)", url: "https://twitter.com/yourprofile" },
    Pinterest: {
      display_label: "Pinterest",
      url: "https://pinterest.com/yourprofile",
    },
    TikTok: { display_label: "TikTok", url: "https://tiktok.com/@yourprofile" },
    Snapchat: {
      display_label: "Snapchat",
      url: "https://snapchat.com/add/yourprofile",
    },
    Telegram: { display_label: "Telegram", url: "https://t.me/yourprofile" },
    WhatsApp: { display_label: "WhatsApp", url: "" },
    Discord: {
      display_label: "Discord",
      url: "https://discord.com/invite/yourserver",
    },
    WeChat: { display_label: "WeChat", url: "https://wechat.com/yourprofile" },
    Line: { display_label: "Line", url: "https://line.me/ti/p/yourprofile" },
    Signal: { display_label: "Signal", url: "https://signal.me/#p/+123456789" },
    Phone: { display_label: "Phone", url: "" },
    YouTube: {
      display_label: "YouTube",
      url: "https://youtube.com/c/yourchannel",
    },
    GitHub: { display_label: "GitHub", url: "https://github.com/yourprofile" },
    PDF: { display_label: "PDF", url: "https://example.com/yourfile.pdf" },
    Address: {
      display_label: "Address",
      url: "https://maps.google.com/?q=Your+Address",
    },
  };

  const SECTIONS = [
    {
      title: "Most Popular",
      items: ["Website", "Link", "Instagram", "Email", "LinkedIn", "Facebook"],
    },
    {
      title: "Social",
      items: [
        "X",
        "Facebook",
        "Instagram",
        "LinkedIn",
        "Pinterest",
        "TikTok",
        "Snapchat",
      ],
    },
    {
      title: "Communications",
      items: [
        "WhatsApp",
        "Telegram",
        "Discord",
        "WeChat",
        "Line",
        "Signal",
        "Phone",
        "Email",
      ],
    },
    {
      title: "Video",
      items: ["YouTube", "TikTok"],
    },
    {
      title: "Other",
      items: ["Website", "Link", "GitHub", "PDF", "Address"],
    },
  ];

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // If there is no destination, exit early
    if (!destination) return;

    const socialMediaLinksCopy = [...request.socialMediaLinks];

    // Reordering items within the same array (socialMediaLinks)
    if (source.droppableId === destination.droppableId) {
      const [removed] = socialMediaLinksCopy.splice(source.index, 1);
      socialMediaLinksCopy.splice(destination.index, 0, removed);

      // Update the state with the reordered socialMediaLinks
      setRequest({ ...request, socialMediaLinks: socialMediaLinksCopy });
    }
  };

  const renderDraggable = useDraggableInPortal();
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    border: isDragging && "1px solid #dadce0",
    borderRadius: isDragging && "5px",
    padding: isDragging && "10px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleRemoveURL = (index: number) => {
    let filtered = getValue(request, `socialMediaLinks`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest((prevState: any) => ({
      ...prevState,
      socialMediaLinks: filtered,
    }));
  };
  console.log(getValue(request, `socialMediaLinks`, []));
  return (
    <div className="d-flex align-items-center w-100 gap-3">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="urls_field_container w-50">
          <div className="p-3">
            <Droppable droppableId="source">
              {(provided) => (
                <div
                  className={"draganddrop_list"}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {getValue(request, `socialMediaLinks.length`, 0) > 0 ? (
                    getValue(request, `socialMediaLinks`, []).map(
                      (item: object, index: number) => (
                        <Draggable
                          key={index}
                          draggableId={`${index}`}
                          index={index}
                        >
                          {renderDraggable((provided: any, snapshot: any) => (
                            <div
                              className={`source-item d-flex justify-content-between mb-2`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="social_media_container w-100">
                                <Form labelCol={{ span: 16 }} layout="vertical">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="cursor-pointer">
                                      <DragIndicatorSvgComponent
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                        }}
                                      />
                                    </div>
                                    <h6 className="header_text__16">
                                      {getValue(item, `value`, "")}
                                    </h6>
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => handleRemoveURL(index)}
                                    >
                                      <CloseCircleOutlined
                                        style={{
                                          fontSize: "22px",
                                          color: "gray",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-start mt-3">
                                    <div className="w-100">
                                      <Form.Item vertical>
                                        <Input
                                          size="large"
                                          name="URL"
                                          value={getValue(item, `URL`, "")}
                                          onChange={(e: any) =>
                                            handleTextArrayChange(
                                              index,
                                              e,
                                              "socialMediaLinks",
                                              setRequest
                                            )
                                          }
                                          placeholder={`Please enter ${getValue(
                                            item,
                                            `value`,
                                            ""
                                          )}`}
                                        />
                                      </Form.Item>
                                      <Form.Item vertical>
                                        <Input
                                          size="large"
                                          name="display_label"
                                          value={getValue(
                                            item,
                                            `display_label`,
                                            ""
                                          )}
                                          onChange={(e: any) =>
                                            handleTextArrayChange(
                                              index,
                                              e,
                                              "socialMediaLinks",
                                              setRequest
                                            )
                                          }
                                          placeholder={`Enter Display Text`}
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          ))}
                        </Draggable>
                      )
                    )
                  ) : (
                    <p className="text-center small_text__14 mt-4">
                      Click the field you want to add to your card.
                    </p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
      <div className="urls_container w-50">
        <div className="p-3">
          {SECTIONS.map((section, index) => (
            <div className="mt-4" key={index}>
              <p className="small_text__14">{section.title}</p>
              <div className="d-flex flex-wrap gap-1">
                {section.items.map((item) => {
                  // const IconComponent = ICONS_MAP[item];
                  const isActive = !!getValue(
                    request,
                    `socialMediaLinks`,
                    []
                  ).find((link: any) => getValue(link, `value`, "") === item);
                  return (
                    <div
                      className={`url_container px-2 ${
                        isActive ? "active" : ""
                      }`}
                      style={
                        {
                          // background: isActive
                          //   ? getValue(request, `settings.theme_color`, "")
                          //   : null,
                          //   borderColor: isActive
                          //   ? getValue(request, `settings.theme_color`, "")
                          //   : null,
                        }
                      }
                      onClick={() => handleSelectURL(item)}
                      key={item}
                    >
                      {getCardIconComponent(
                        item,
                        20,
                        isActive ? "#fff" : "#000"
                      )}
                      <p className="mt-3 small_text__14 ms-1">{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SocialMediaPage;
