import React from "react";
import { getValue } from "@utils/lodash";

function CardPdfIcon(props:any) {
  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          d="M5 3C5 2.44772 5.44771 2 6 2H12.4934V6.6C12.4934 7.70457 13.3888 8.6 14.4934 8.6H19V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V3ZM6 0C4.34315 0 3 1.34314 3 3V21C3 22.6569 4.34315 24 6 24H18C19.6569 24 21 22.6569 21 21V7.18995L13.9118 0H6ZM14.5 13C14.5 11.6 13.4 10.5 12 10.5C10.6 10.5 9.5 11.6 9.5 13H9C7.9 13 7 13.9 7 15C7 16.1 7.9 17 9 17H15C16.1 17 17 16.1 17 15C17 13.9 16.1 13 15 13H14.5Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
        ></path>
      </svg>
    </div>
  );
}

export default CardPdfIcon;
