import { getValue } from "@utils/lodash";
import { getCardIconComponent } from "@common/card-icons-helper";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useState } from "react";
import ArrowUpSvgComponent from "@assets/svg/arrow-up";
import ArrowDownSvgComponent from "@assets/svg/arrow-down";

function CardPreviewSocialMedia(props: any) {
  const { request } = props;
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  return (
    <div>
      <div className="d-flex  flex-column">
        {getValue(request, `socialMediaLinks`, []).map(
          (item: object, index: number) => {
            if (
              getValue(request, `card_type`, "") === "business"
                ? !showAll
                  ? index < 5
                  : index > -1
                : index > -1
            )
              return (
                <a
                  className="d-flex align-items-center mt-2 cursor-pointer"
                  href={getValue(item, `URL`, "") && getValue(item, `URL`, "")}
                  target="_blank"
                  key={index}
                >
                  <div
                    className="icon_circle_container"
                    style={{
                      background: getValue(request, `settings.theme_color`, ""),
                    }}
                  >
                    {getCardIconComponent(
                      getValue(item, `value`, ""),
                      20,
                      "#fff"
                    )}
                  </div>
                  <h6 className="ms-2 mt-2 header_text__16">
                    {getValue(item, `display_label`, "")}
                  </h6>
                </a>
              );
          }
        )}
        {getValue(request, `card_type`, "") === "business" &&
          getValue(request, `socialMediaLinks.length`, 0) > 5 && (
            <div
              className="d-flex align-items-center justify-content-end cursor-pointer"
              onClick={() => setShowAll(!showAll)}
            >
              <p className="small_text__14 mt-3">
                View {showAll ? "Less" : "All"}
              </p>
              <div>
                {showAll ? (
                  <ArrowUpSvgComponent size={24} color={"#000"} />
                ) : (
                  <ArrowDownSvgComponent size={24} color={"#000"} />
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default CardPreviewSocialMedia;
