import { QueryRequestHelper } from "@common/query-request-helper";
import HomeLayout from "@layouts/HomeLayout";
import { deleteCard, getAllCards } from "@services/cards.service";
import { getValue } from "@utils/lodash";
import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Card, message, Popconfirm, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import NoDataFound from "more/nodata";
import Loader from "more/loader";

function ContactsPage(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queyRequest = QueryRequestHelper(payload);
      let resp = await getAllCards(queyRequest);
      if (resp) {
        console.log(resp);
        setList(getValue(resp, `data.cards`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleEditClick = (item: object) => {
    navigate(
      `/create-card/${getValue(item, `card_type`, "")}/${getValue(
        item,
        `id`,
        ""
      )}`
    );
  };

  const handleDelete = async (id: string) => {
    try {
      let resp = await deleteCard(id);
      if (resp) {
        message.success(getValue(resp, `message`, ""));
        getData();
      }
    } catch (error) {}
  };

  const handleView = async (id: string) => {};
  return (
    <HomeLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-wrap gap-4">
          {getValue(list, `length`, 0) > 0 ? (
            list.map((item: object) => {
              return (
                <Badge.Ribbon
                  text={getValue(item, `card_type`, "")}
                  color={
                    getValue(item, `card_type`, "") === "personal"
                      ? "green"
                      : "pink"
                  }
                >
                  <Card
                    style={{ width: 250, height: 200 }}
                    // cover={
                    //   <img
                    //     alt="example"
                    //     src={getValue(
                    //       item,
                    //       `userInfo.profile_logo[${0}].url`,
                    //       ""
                    //     )}
                    //   />
                    // }
                    // actions={[
                    //   <Tooltip title="Edit">
                    //     <EditOutlined
                    //       key="edit"
                    //       onClick={() => handleEditClick(item)}
                    //     />
                    //   </Tooltip>,
                    //   <Popconfirm
                    //     title="Are you sure you want to delete?"
                    //     okText="Yes"
                    //     cancelText="No"
                    //     onConfirm={() =>
                    //       handleDelete(getValue(item, `id`, ""))
                    //     }
                    //   >
                    //     <Tooltip title="Delete">
                    //       <DeleteOutlined />
                    //     </Tooltip>
                    //   </Popconfirm>,
                    //   <Tooltip title="View">
                    //     <EyeOutlined
                    //       key="settings"
                    //       onClick={() => handleView(getValue(item, `id`, ""))}
                    //     />
                    //   </Tooltip>,
                    // ]}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/contacts/${getValue(item, `id`, "")}`)
                    }
                  >
                    <div className="mt-4">
                      <Meta
                        avatar={
                          <Avatar
                            src={getValue(
                              item,
                              `aboutInfo.business_logo[${0}].url`,
                              ""
                            )}
                          />
                        }
                        title={getValue(item, `userInfo.user_name`, "")}
                        description={getValue(
                          item,
                          `userInfo.user_designation`,
                          ""
                        )}
                        style={{ height: "50px" }}
                      />
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <div>
                        <Badge
                          count={getValue(item, `contactCount`, 0)}
                          showZero
                        >
                          <ContactsOutlined
                            style={{ fontSize: "30px", color: "#38629A" }}
                          />
                        </Badge>
                      </div>
                    </div>
                  </Card>
                </Badge.Ribbon>
              );
            })
          ) : (
            <NoDataFound title={"No Contacts Found"} />
          )}
        </div>
      )}
    </HomeLayout>
  );
}

export default ContactsPage;
