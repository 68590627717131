import PageLayout from "@layouts/PageLayout";
import { useEffect, useRef, useState } from "react";
import { Button, message, Popconfirm, Tabs } from "antd";
import CardDisplayPage from "../components/display";
import CardInformationPage from "../components/user-info";
import CardCompanyInfoPage from "../components/company-info";
import SocialMediaPage from "../components/social-media";
import BankDetailsPage from "../components/bank-details";
import GalleriesPage from "../components/galleries";
import ProductServicesPage from "../components/product-services";
import MorePage from "../components/more";
import {
  createCard,
  getSpecificCard,
  updateCard,
} from "@services/cards.service";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import CardPreviewPage from "../../Designs/Design1";
import Confetti from "react-confetti";
import { bussinesIntialState } from "../../components/card-request-helper";
import {
  cleanObject,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import Design1Page from "../../Designs/Design1";
import Design2Page from "../../Designs/Design2";
import Design3Page from "../../Designs/Design3";

function CreateBusinessCardPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState(bussinesIntialState);
  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
    }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      let resp = await getSpecificCard(getValue(params, `id`, ""));
      if (resp) {
        setRequest(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  }; /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const items: any = [
    {
      key: 1,
      label: `Display`,
      children: (
        <div className="tab-content-container">
          <CardDisplayPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 2,
      label: `Personal Info`,
      children: (
        <div className="tab-content-container">
          <CardInformationPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 3,
      label: `Company Info`,
      children: (
        <div className="tab-content-container">
          <CardCompanyInfoPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 4,
      label: `URLs`,
      children: (
        <div className="tab-content-container">
          <SocialMediaPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 5,
      label: `Bank`,
      children: (
        <div className="tab-content-container">
          <BankDetailsPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 6,
      label: `Galleries`,
      children: (
        <div className="tab-content-container">
          <GalleriesPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 7,
      label: `Products/Services`,
      children: (
        <div className="tab-content-container">
          <ProductServicesPage request={request} setRequest={setRequest} />
        </div>
      ),
    },
    {
      key: 8,
      label: `More`,
      children: (
        <div className="tab-content-container">
          <MorePage request={request} setRequest={setRequest} />
        </div>
      ),
    },
  ];

  const [position, setPosition] = useState<"start" | "end">("end");
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp;
      let payload = JSON.parse(JSON.stringify(request));
      let requestPayload = cleanObject(payload);
      requestPayload["socialMediaLinks"] =
        getValue(requestPayload, `socialMediaLinks.length`, 0) > 0 &&
        getValue(requestPayload, `socialMediaLinks`, []).map(
          (item: object, index: number) => ({
            ...item,
            seq_num: index + 1,
          })
        );

      if (getValue(params, `id`, "")) {
        resp = await updateCard(getValue(params, `id`, ""), requestPayload);
      } else {
        resp = await createCard(requestPayload);
      }
      if (resp) {
        setShowConfetti(true);
        message.success(getValue(resp, `message`, ""));
        setSubmitLoading(false);
        setTimeout(() => {
          navigate("/cards");
        }, 500);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [showConfetti, setShowConfetti] = useState(false);

  const validateUserInfoAndSocialLinks = () => {
    const { userInfo, socialMediaLinks } = request;
    return userInfo.user_name !== "" && socialMediaLinks.length > 0;
  };

  return (
    <PageLayout>
      <div className="d-flex m-2 justify-content-between">
        <div className="card_preview_section">
          {getValue(request, `card_design`, "") === "design_1" && (
            <Design1Page request={request} />
          )}
          {getValue(request, `card_design`, "") === "design_2" && (
            <Design2Page request={request} />
          )}
          {getValue(request, `card_design`, "") === "design_3" && (
            <Design3Page request={request} />
          )}
        </div>
        <div className="card_edit_section p-3">
          <Tabs defaultActiveKey="2" items={items} />
          <div className="create_card_footer w-100 d-flex justify-content-between align-items-center">
            <div></div>
            <div className="mx-5">
              <Button
                style={{}}
                onClick={() => navigate("/cards")}
                size="large"
              >
                Cancel
              </Button>
              <Popconfirm
                title="Are you sure you want to submit?"
                okText="Yes"
                cancelText="No"
                className="ms-2"
                onConfirm={handleSubmit}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitLoading}
                  size="large"
                  style={{ background: "#38629A" }}
                  disabled={!validateUserInfoAndSocialLinks() ? true : false}
                >
                  {getValue(params, `id`, "") ? "Update" : "Submit"}
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
        {/* {showConfetti && (
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        )} */}
      </div>
    </PageLayout>
  );
}

export default CreateBusinessCardPage;
