import { useEffect } from "react";
import { getValue } from "@utils/lodash";
import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { PhoneInput } from "react-international-phone";
import DraftJSEditor from "@common/custom/DraftJSEditor";
import { emojisList } from "@common/emojis";

function CardInformationPage(props: any) {
  const { request, setRequest } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      user_name: getValue(request, `userInfo.user_name`, ""),
      user_email: getValue(request, `userInfo.user_email`, ""),
      user_designation: getValue(request, `userInfo.user_designation`, ""),
      whatsapp_number: getValue(request, `userInfo.whatsapp_number`, ""),
      contact_number: getValue(request, `userInfo.contact_number`, ""),
      user_address: getValue(request, `userInfo.user_address`, ""),
      user_info: getValue(request, `userInfo.user_info`, ""),
    });
  }, [request, form]);

  const handleChange = (e: any, key: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [key]: {
        ...request[key],
        [name]: value,
      },
    });
  };
  // accreditations:'',
  // prefered_name:'',
  // mainden_name:'',
  // pronouns:'',

  const handleChangeEditor = (name: any, value: any, editorState: any) => {
    setRequest({
      ...request,
      userInfo: {
        ...request.userInfo,
        [name]: value,
      },
    });
  };
  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}
      layout="vertical"
    >
      <Form.Item
        label="User Name *"
        rules={[{ required: true, message: "User name is required" }]}
      >
        <Input
          size="large"
          name="user_name"
          value={getValue(request, `userInfo.user_name`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter user name"
        />
      </Form.Item>
      {/* <Form.Item label="Maiden Name">
        <Input
          size="large"
          name="maiden_name"
          value={getValue(request, `userInfo.maiden_name`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter Maiden Name"
        />
      </Form.Item> */}
      <Form.Item label="Designation">
        <Input
          size="large"
          name="accreditations"
          value={getValue(request, `userInfo.accreditations`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter Designation"
        />
      </Form.Item>
      <Form.Item label="Prefered Name">
        <Input
          size="large"
          name="prefered_name"
          value={getValue(request, `userInfo.prefered_name`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter Prefered Name"
        />
      </Form.Item>
      <Form.Item label="Pronouns">
        <Input
          size="large"
          name="pronouns"
          value={getValue(request, `userInfo.pronouns`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter Pronouns (he/him/his)"
        />
      </Form.Item>
      {/* <Form.Item label="Whatsapp Number">
        <PhoneInput
          placeholder="Enter phone number"
          value={getValue(request, `userInfo.whatsapp_number`, "")}
          defaultCountry="in"
          onChange={(value: any) =>
            setRequest({
              ...request,
              userInfo: {
                ...request.userInfo,
                whatsapp_number: value,
              },
            })
          }
        />
      </Form.Item>
      <Form.Item label="Contact Number">
        <PhoneInput
          placeholder="Enter contact number"
          value={getValue(request, `userInfo.contact_number`, "")}
          defaultCountry="in"
          onChange={(value: any) =>
            setRequest({
              ...request,
              userInfo: {
                ...request.userInfo,
                contact_number: value,
              },
            })
          }
        />
      </Form.Item>
      <Form.Item label="Email">
        <Input
          size="large"
          name="user_email"
          value={getValue(request, `userInfo.user_email`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter Email"
        />
      </Form.Item> */}
      {/* <Form.Item label="Designation">
        <Input
          size="large"
          name="user_designation"
          value={getValue(request, `userInfo.user_designation`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter user designation"
        />
      </Form.Item> */}
      <div className="w-100">
        <Form.Item label="About you">
          {/* <TextArea
          size="large"
          name="user_info"
          value={getValue(request, `userInfo.user_info`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter About you"
        /> */}

          <DraftJSEditor
            editorState={
              getValue(request, `userInfo.user_info`, "")
                ? getValue(request, `userInfo.user_info`, "<p></p>\n")
                : "<p></p>\n"
            }
            handleChangeEditor={handleChangeEditor}
            name="user_info"
            placeholder="Enter here"
            editorStyle={{
              background: "white",
              paddingLeft: "20px",
              paddingTop: "20px",
              // height: "30vh",
            }}
            focusColor={"#1a73e8"}
            toolbar={{
              options: ["inline", "emoji"],
              inline: {
                options: ["bold", "italic"],
              },
              emoji: {
                emojis: emojisList,
              },
            }}
            hideFocus
            focus
            editorClassName={"bot-editorClassName"}
          />
        </Form.Item>
      </div>
      <Form.Item label="Address">
        <TextArea
          size="large"
          name="user_address"
          value={getValue(request, `userInfo.user_address`, "")}
          onChange={(e: any) => handleChange(e, "userInfo")}
          placeholder="Enter Address"
        />
      </Form.Item>
    </Form>
  );
}

export default CardInformationPage;
