import React, { useState } from "react";
import { Avatar, Card, Popover, Tooltip, Popconfirm } from "antd";
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "@context/navigation-provider";
import { getValue } from "@utils/lodash";
import { getFirstLetterOfWord } from "@common/text-helpers";

import "./index.scss";

const ProfilePage = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useNavigation();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleEditClick = () => {
    navigate(`/accounts`);
  };

  const handleLogoutClick = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const actions: React.ReactNode[] = [
    <Tooltip title="Account Settings" key="account">
      <EditOutlined key="edit" onClick={handleEditClick} />
    </Tooltip>,
    <Tooltip title="Logout" key="logout">
      <Popconfirm
        title="Are you sure you want to logout?"
        okText="Yes"
        cancelText="No"
        onConfirm={handleLogoutClick}
      >
        <LogoutOutlined />
      </Popconfirm>
    </Tooltip>,
  ];

  const ProfileContent = () => {
    return (
      <Card style={{ width: 240 }} actions={actions}>
        <div className="profile_container d-flex justify-content-center align-items-center">
          <div className="account_circle" style={{ background: "#38629A" }}>
            {getValue(userInfo, `profile_pic[${0}].url`, "") ? (
              <img
                src={getValue(userInfo, `profile_pic[${0}].url`, "")}
                className="image_size"
              />
            ) : (
              <h6 className="header_text__24 color_white mt-2">
                {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
              </h6>
            )}
          </div>
        </div>
        <div className="mt-3">
          <h6 className="header_text__16">
            {getValue(userInfo, `first_name`, "")}{" "}
            {getValue(userInfo, `last_name`, "")}
          </h6>
          <p className="small_text__12">{getValue(userInfo, `email`, "")}</p>
        </div>
      </Card>
    );
  };

  return (
    <Popover
      placement="bottomRight"
      content={ProfileContent()}
      title="Account"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      className="mx-4 cursor-pointer"
    >
      {getValue(userInfo, `profile_pic[${0}].url`, "") ? (
        <img
          src={getValue(userInfo, `profile_pic[${0}].url`, "")}
          className="image_profile"
        />
      ) : (
        <Avatar style={{ backgroundColor: "#38629A" }}>
          {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
        </Avatar>
      )}
    </Popover>
  );
};

export default ProfilePage;
