import LogoSvgComponent from "@assets/svg/logo";
import React from "react";
import LogoTextComponent from "./LogoTextComponent";
import LogoNewSvgComponent from "@assets/svg/logo-new";

function SideMenuLogo(props: any) {
  const { collapsed } = props;
  return (
    <div
      className="demo-logo-vertical d-flex align-items-center justify-content-center"
      style={{ height: "60px" }}
    >
      <LogoNewSvgComponent />
      {!collapsed && <LogoTextComponent />}
    </div>
  );
}

export default SideMenuLogo;
