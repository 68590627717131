import { Button, Popconfirm, Space, Table, Tooltip } from "antd";
import type { TableProps } from "antd";
import { useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import ProductServiceModal from "./drawer/product-modal"; // Adjust this import based on your file structure
import { getValue, setValue } from "@utils/lodash";

interface DataType {
  key: string;
  ps_type: string;
  ps_name: string;
  ps_images: Array<{ url: string }>;
  ps_currency: string;
  ps_price: string;
}

function ProductServicesPage(props: any) {
  const { request, setRequest } = props;

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Type",
      dataIndex: "ps_type",
      key: "ps_type",
    },
    {
      title: "Name",
      dataIndex: "ps_name",
      key: "ps_name",
    },
    {
      title: "Image",
      dataIndex: "ps_images",
      key: "ps_images",
      render: (images) => {
        return (
          <Space>
            {getValue(images, `length`, 0) > 0
              ? images.map((img: { url: string }, index: number) => (
                  <img
                    key={index}
                    src={img.url}
                    alt={`Product ${index}`}
                    style={{ width: 50, height: 50, objectFit: "cover" }}
                  />
                ))
              : []}
          </Space>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "ps_price",
      key: "ps_price",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => handleEdit(parseInt(getValue(record, `key`, "")))}
            ></Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure you want to delete?"
              okText="Yes"
              cancelText="No"
              className="ms-2"
              onConfirm={() =>
                handleDelete(parseInt(getValue(record, `key`, "")))
              }
            >
              <Button icon={<DeleteOutlined />} danger></Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data: DataType[] = getValue(request, `productServices`, []).map(
    (item: any, index: number) => ({
      key: index.toString(),
      ...item,
    })
  );

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.ps_name === "Disabled User",
      name: record.ps_name,
    }),
  };

  const [open, setOpen] = useState(false);
  const [selectionType, setSelectionType] = useState<any>("checkbox");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const initialState = {
    ps_type: "product",
    ps_name: "",
    ps_images: [],
    ps_currency: "INR",
    ps_price: "0",
  };
  const [requestObj, setRequestObj] = useState<any>(initialState);
  const handleSubmit = () => {
    console.log(requestObj);
    if (editId || editId === 0) {
      setValue(request, `productServices[${editId}]`, requestObj);
      setRequest({ ...request });
    } else {
      getValue(request, `productServices`, []).push(requestObj);
      setRequest({ ...request });
    }
    onClose();
    reset();
  };

  const [editId, setEditId] = useState<any>(null);
  const reset = () => {
    setRequestObj({
      ps_type: "product",
      ps_name: "",
      ps_images: [],
      ps_currency: "INR",
      ps_price: "0",
    });
    setEditId(null);
  };
  console.log(editId);

  const handleEdit = (index: number) => {
    setEditId(index);
    let findObj = getValue(request, `productServices`, []).find(
      (item: object, i: number) => i === index
    );
    setRequestObj({
      ps_type: getValue(findObj, `ps_type`, ""),
      ps_name: getValue(findObj, `ps_name`, ""),
      ps_images: getValue(findObj, `ps_images`, ""),
      ps_currency: getValue(findObj, `ps_currency`, ""),
      ps_price: getValue(findObj, `ps_price`, ""),
    });
    showDrawer();
  };

  const handleDelete = (index: number) => {
    setEditId(index);
    let filtered = getValue(request, `productServices`, []).filter(
      (item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      productServices: filtered,
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div></div>
        <Button type="primary" icon={<PlusOutlined />} onClick={showDrawer}>
          Add
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
      />
      <ProductServiceModal
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        request={requestObj}
        setRequest={setRequestObj}
        handleSubmit={handleSubmit}
        editId={editId}
      />
    </div>
  );
}

export default ProductServicesPage;
