import CardWebsiteIcon from "@assets/svg/card-svg/card_website";
import CardLinkIcon from "@assets/svg/card-svg/card_link";
import CardInstaIcon from "@assets/svg/card-svg/card_insta";
import CardEmailIcon from "@assets/svg/card-svg/card_email";
import CardLinkdinIcon from "@assets/svg/card-svg/card_linkedin";
import CardFbIcon from "@assets/svg/card-svg/card_fb";
import CardXIcon from "@assets/svg/card-svg/card_x";
import CardPinterestIcon from "@assets/svg/card-svg/card_pinterest";
import CardTiktok from "@assets/svg/card-svg/card_tiktok";
import CardSnapchatIcon from "@assets/svg/card-svg/card_snapchat";
import CardWhatsappIcon from "@assets/svg/card-svg/card_whatsapp";
import CardTelegramIcon from "@assets/svg/card-svg/card_telegram";
import CardDiscordIcon from "@assets/svg/card-svg/card_discord";
import CardWechatIcon from "@assets/svg/card-svg/card_wechat";
import CardLineIcon from "@assets/svg/card-svg/card_line";
import CardSignalIcon from "@assets/svg/card-svg/card_signal";
import CardYoutubeIcon from "@assets/svg/card-svg/card_youtube";
import CardGithubIcon from "@assets/svg/card-svg/card_github";
import CardPdfIcon from "@assets/svg/card-svg/card_pdf";
import CardLocationIcon from "@assets/svg/card-svg/card_location";
import PhoneSvgComponent from "@assets/svg/custom/phone";
export const getCardIconComponent = (
  item: string,
  size: number,
  color: string
) => {
  switch (item) {
    case "Website":
      return <CardWebsiteIcon size={size} color={color} />;
    case "Link":
      return <CardLinkIcon size={size} color={color} />;
    case "Instagram":
      return <CardInstaIcon size={size} color={color} />;
    case "Email":
      return <CardEmailIcon size={size} color={color} />;
    case "LinkedIn":
      return <CardLinkdinIcon size={size} color={color} />;
    case "Facebook":
      return <CardFbIcon size={size} color={color} />;
    case "X":
      return <CardXIcon size={16} color={color} />;
    case "Pinterest":
      return (
        <div className="mt-3">
          <CardPinterestIcon size={size} color={color} />
        </div>
      );
    case "TikTok":
      return <CardTiktok size={size} color={color} />;
    case "Snapchat":
      return <CardSnapchatIcon size={size} color={color} />;
    case "WhatsApp":
      return <CardWhatsappIcon size={size} color={color} />;
    case "Phone":
      return <PhoneSvgComponent size={size} color={color} />;
    case "Telegram":
      return <CardTelegramIcon size={size} color={color} />;
    case "Discord":
      return <CardDiscordIcon size={size} color={color} />;
    case "WeChat":
      return <CardWechatIcon size={size} color={color} />;
    case "Line":
      return <CardLineIcon size={size} color={color} />;
    case "Signal":
      return <CardSignalIcon size={size} color={color} />;
    case "YouTube":
      return <CardYoutubeIcon size={size} color={color} />;
    case "GitHub":
      return <CardGithubIcon size={size} color={color} />;
    case "PDF":
      return <CardPdfIcon size={size} color={color} />;
    case "Address":
      return <CardLocationIcon size={size} color={color} />;
    default:
      return null;
  }
};
