import { MessageOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";

function isVideo(url: string) {
  return url && (url.endsWith(".mp4") || url.includes("video"));
}

function CardPreviewTitleHeader3(props: any) {
  const { request } = props;
  const imageUrl = getValue(request, "aboutInfo.business_logo[0].url", "");

  return (
    <>
      <div className="logo_container__3">
        <div className="profile_logo_container__3">
          {imageUrl && (
            <div className="card_preview_header_logo__3">
              {isVideo(imageUrl) ? (
                <video
                  src={imageUrl}
                  controls
                  className="card_preview_header_logo_video"
                />
              ) : (
                <img src={imageUrl} alt="Business Logo" />
              )}
            </div>
          )}

          <div className="card_preview_title_header_container__3">
            <div className="identity__3">
              {getValue(request, `userInfo.user_name`, "") && (
                <p className="header">
                  {getValue(request, `userInfo.user_name`, "")}
                  {getValue(request, `userInfo.maiden_name`, "") && (
                    <span className="ms-2">
                      {getValue(request, `userInfo.maiden_name`, "")}
                    </span>
                  )}
                </p>
              )}
              <p className="small_text__16">
                {getValue(request, `userInfo.accreditations`, "")}
              </p>
            </div>
          </div>
        </div>

        {(getValue(request, `userInfo.prefered_name`, "") ||
          getValue(request, `userInfo.pronouns`, "")) && (
          <div className="card_preview_preferred_container mt-3">
            <MessageOutlined />
            <p className="text mt-3">
              Goes by {getValue(request, `userInfo.prefered_name`, "")}{" "}
              {getValue(request, `userInfo.pronouns`, "") && (
                <span className="ms-1">
                  {getValue(request, `userInfo.pronouns`, "")}
                </span>
              )}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default CardPreviewTitleHeader3;
