import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import ArrowUpSvgComponent from "@assets/svg/arrow-up";
import { getValue } from "@utils/lodash";
import { Image } from "antd";
import React, { useState } from "react";

function CardCompanyDocuments(props: any) {
  const { request } = props;
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <h6 className="header_text__22 text-center mt-4 mb-4 color_desc">
        Documents
      </h6>
      <div className="d-flex flex-wrap gap-1 ">
        {getValue(request, `aboutInfo.documents`, []).map(
          (item: any, index: number) => {
            if (!showAll ? index < 3 : index > -1)
              return (
                <div className="d-flex flex-column">
                  <div
                    key={index}
                    className="image-container gallery_container"
                  >
                    <Image
                      src={item.url || item.preview} // Display the image thumbnail
                      alt={`Image ${index + 1}`}
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        cursor: "pointer",
                        border: "1px solid #efefef",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <p className="small_text__14 mt-2 text-center">
                    {getValue(item, `description`, "")}
                  </p>
                </div>
              );
          }
        )}
      </div>
      {getValue(request, `aboutInfo.documents.length`, 0) > 3 && (
        <div
          className="d-flex align-items-center justify-content-end cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          <p className="small_text__14 mt-3">View {showAll ? "Less" : "All"}</p>
          <div>
            {showAll ? (
              <ArrowUpSvgComponent size={24} color={"#000"} />
            ) : (
              <ArrowDownSvgComponent size={24} color={"#000"} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CardCompanyDocuments;
