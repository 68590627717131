import ContactsCardPage from "@pages/private/Contacts";
import ContactsPage from "@pages/private/Contacts/contact-list";

export const ContactsRoutes = [
  {
    path: "contacts",
    name: "Contacts",
    component: ContactsCardPage,
  },
  {
    path: "/contacts/:id",
    name: "Contacts",
    component: ContactsPage,
  },
];
