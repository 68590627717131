import DesignsPage from "@pages/private/Designs";
import HomePage from "@pages/private/Dashboard";

export const DesignRoutes = [
  {
    path: "/designs",
    name: "designs",
    component: DesignsPage,
  },
];
