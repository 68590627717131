import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import ArrowUpSvgComponent from "@assets/svg/arrow-up";
import { getValue } from "@utils/lodash";
import { Badge, Image } from "antd";
import React, { useState } from "react";

function CardPreviewProductService(props: any) {
  const { request } = props;
  const [showAll, setShowAll] = useState(false);
  return (
    <>
      <h6 className="header_text__22 text-center mt-4 mb-3 color_desc">
        Our Menu
      </h6>
      <div className="d-flex flex-wrap gap-1 ">
        {getValue(request, `productServices`, []).map(
          (item: any, index: number) => {
            if (!showAll ? index < 3 : index > -1)
              return (
                <div>
                  <div
                    key={index}
                    className="image-container gallery_container"
                  >
                    {getValue(item, `ps_images.length`, 0) > 0 && (
                      <Badge title={getValue(item, `ps_type`, "")}>
                        <Image
                          src={item.ps_images[0].url || item.preview} // Display the image thumbnail
                          alt={`Image ${index + 1} `}
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "contain",
                            cursor: "pointer",
                            border: "1px solid #efefef",
                            borderRadius: "5px",
                          }}
                        />
                      </Badge>
                    )}
                  </div>
                  <p className="small_text__14">
                    {getValue(item, `ps_name`, "")}
                  </p>
                  <p className="small_text__14">
                    INR {getValue(item, `ps_price`, "")}
                  </p>
                </div>
              );
          }
        )}
      </div>
      {getValue(request, `productServices.length`, 0) > 3 && (
        <div
          className="d-flex align-items-center justify-content-end cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          <p className="small_text__14 mt-3">View {showAll ? "Less" : "All"}</p>
          <div>
            {showAll ? (
              <ArrowUpSvgComponent size={24} color={"#000"} />
            ) : (
              <ArrowDownSvgComponent size={24} color={"#000"} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CardPreviewProductService;
