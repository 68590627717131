import { getValue } from "@utils/lodash";
import React from "react";

function Design3SvgComponent(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 72 72"
        focusable="false"
        className="chakra-icon chakra-icon css-1kqumke"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "34"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "34"
        }
      >
        <g clip-path="url(#a)">
          <rect fill="#F5F5F5" height="72" rx="16" width="72"></rect>
          <circle
            cx="36"
            cy="-6.75"
            fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#fff"
            }
            r="59.625"
          ></circle>
          <path d="M15.75 42.75h41.625v13.5H15.75z" fill="#fff"></path>
        </g>
        <defs>
          <clipPath id="a">
            <rect fill="#fff" height="72" rx="16" width="72"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Design3SvgComponent;
