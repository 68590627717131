import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const CardGoogleMapSection = (props: any) => {
  const mapStyles = {
    height: "200px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 40.748817, // Replace with your latitude
    lng: -73.985428, // Replace with your longitude
  };

  return (
    <>
      <h6 className="header_text__22 text-center mt-4 mb-4 color_desc">
        Google Map
      </h6>
      <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={defaultCenter}
        >
          <Marker position={defaultCenter} />
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default CardGoogleMapSection;
