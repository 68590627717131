import { QueryRequestHelper } from "@common/query-request-helper";
import HomeLayout from "@layouts/HomeLayout";
import { getAllContacts } from "@services/contacts.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { Table, Button, Popconfirm, Space, Tag, Badge } from "antd";
import { useNavigate, useParams } from "react-router-dom";

function ContactsPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);

  // Table columns
  const columns = [
    {
      title: "Card Info",
      dataIndex: "full_name",
      key: "full_name",
      render: (text: any, record: any) => (
        <div className="d-flex">
          <p className="small_text__14">{record.card.userInfo.user_name}</p>
          <div
            style={{
              position: "absolute",
              right: "0px",
              top: "-15px",
              zIndex: 1,
            }}
          >
            <Badge.Ribbon
              color={record.card.card_type === "business" ? "green" : "red"}
              text={record.card.card_type}
            ></Badge.Ribbon>
          </div>
        </div>
      ),
    },
    {
      title: "Contact Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text: any, record: any) => record.full_name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Active Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (isActive: any) => (isActive ? "Active" : "Inactive"),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => new Date(created_at).toLocaleString(),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_: any, record: any) => (
    //     <Popconfirm
    //       title="Are you sure you want to delete this record?"
    //       onConfirm={() => handleDelete(record.id)}
    //       okText="Yes"
    //       cancelText="No"
    //     >
    //       <Button type="primary" danger>
    //         Delete
    //       </Button>
    //     </Popconfirm>
    //   ),
    // },
  ];
  // Delete record function
  const handleDelete = (id: any) => {
    // setDataSource(dataSource.filter((item) => item.id !== id));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [selectionType, setSelectionType] = useState<any>("checkbox");
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        card_id: getValue(params, `id`, ""),
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllContacts(queryRequest);
      if (resp) {
        setContacts(getValue(resp, `data.contacts`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, ""));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: number, page_size: number) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
      limit: page_size,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  return (
    <HomeLayout>
      <Table
        columns={columns}
        dataSource={contacts}
        rowKey="id"
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        pagination={{
          current: page_no,
          pageSize: 10,
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50"],
          onChange: (page, pageSize) => {
            handleChangePagination(page, pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </HomeLayout>
  );
}

export default ContactsPage;
