import React from "react";
import { getValue } from "@utils/lodash";

function CardTelegramIcon(props:any) {
  return (
    <div>
      <svg
        viewBox="0 0 28 23"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          clipRule="evenodd"
          d="M21.16 22.7701C21.505 23.0001 21.965 23.1151 22.425 22.8851C22.885 22.7701 23.115 22.3101 23.23 21.9651C24.265 17.2501 26.68 5.40509 27.6 1.15009C27.715 0.805089 27.6 0.460089 27.255 0.230089C27.025 8.90307e-05 26.68 8.90307e-05 26.335 0.115089C21.505 1.84009 6.67 7.36009 0.575 9.66009C0.23 9.77509 0 10.1201 0 10.5801C0 11.0401 0.345 11.3851 0.69 11.5001C3.45 12.3051 7.015 13.4551 7.015 13.4551C7.015 13.4551 8.74 18.5151 9.545 21.0451C9.66 21.3901 9.89 21.6201 10.235 21.7351C10.58 21.8501 10.925 21.7351 11.155 21.5051C12.535 20.2401 14.72 18.1701 14.72 18.1701C14.72 18.1701 18.86 21.0451 21.16 22.7701ZM8.51 12.7651L10.465 19.0901L10.925 15.0651C10.925 15.0651 18.4 8.28009 22.655 4.48509C22.77 4.37009 22.77 4.14009 22.655 4.02509C22.54 3.91009 22.31 3.91009 22.195 3.91009C17.25 7.13009 8.51 12.7651 8.51 12.7651Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default CardTelegramIcon;
