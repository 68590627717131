import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload, message, Button, Typography } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const SingleUploadComponent: React.FC<any> = (props) => {
  const { title, uploadSuccess, uploadRemove } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewMedia, setPreviewMedia] = useState("");
  const [isVideo, setIsVideo] = useState(false);

  const beforeUpload = (file: FileType) => {
    const isSupportedFile = file.type === "image/jpeg" || file.type === "image/png" || file.type === "video/mp4";
    if (!isSupportedFile) {
      message.error("You can only upload JPG/PNG images or MP4 videos!");
      return Upload.LIST_IGNORE;
    }
    const isLt10M = file.size / 1024 / 1024 < 10; // Adjust size limit as needed for videos
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleCustomRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-media");

    try {
      const resp = await mediaUpload(formData);
      if (resp) {
        const mediaUrl = getValue(resp, `data.url`, "");

        const newFile = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: mediaUrl,
        };
        uploadSuccess(newFile);
      } else {
        throw new Error("Media upload failed.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Media upload failed due to an error.");
      onError(error);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    setIsVideo(file.type === "video/mp4");
    setPreviewMedia(file.url || (await getBase64(file.originFileObj as FileType)));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    let filtered = newFileList.filter((file) => file.status === "done");
    uploadRemove(filtered);
  };

  const uploadButton = (
    <Button style={{ border: 0, background: "none" }} type="link">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Button>
  );

  const { imageUrls } = props;
  const { Title } = Typography;
  return (
    <>
      <div>
        <Title level={5}>{title}</Title>
        <Upload
          multiple
          listType={props.listType ? props.listType : "picture-card"}
          fileList={getValue(imageUrls, `length`, 0) > 0 ? imageUrls : []}
          beforeUpload={beforeUpload}
          customRequest={handleCustomRequest}
          onPreview={handlePreview}
          onChange={handleChange}
          showUploadList={{ showRemoveIcon: true }}
        >
          {getValue(imageUrls, `length`, 0) === 0 ? uploadButton : ""}
        </Upload>
        {previewMedia && (
          <>
            {isVideo ? (
              <video
                style={{ display: "block", width: "100%" }}
                controls
                src={previewMedia}
                onClick={() => setPreviewOpen(false)}
              />
            ) : (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewMedia(""),
                }}
                src={previewMedia}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SingleUploadComponent;
