import HomeLayout from "@layouts/HomeLayout";
import React from "react";
import { Card, Col, Row } from "antd";

function DesignsPage(props: any) {
  return (
    <HomeLayout>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Theme 1" bordered={true}></Card>
        </Col>
        <Col span={8}>
          <Card title="Theme 2" bordered={true}></Card>
        </Col>
        <Col span={8}>
          <Card title="Theme 3" bordered={true}></Card>
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col span={8}>
          <Card title="Theme 4" bordered={true}></Card>
        </Col>
        <Col span={8}>
          <Card title="Theme 5" bordered={true}></Card>
        </Col>
        <Col span={8}>
          <Card title="Theme 6" bordered={true}></Card>
        </Col>
      </Row>
      <Row gutter={16} className="mt-3">
        <Col span={8}>
          <Card title="Theme 7" bordered={true}></Card>
        </Col>
        <Col span={8}>
          <Card title="Theme 8" bordered={true}></Card>
        </Col>
        <Col span={8}>
          <Card title="Theme 9" bordered={true}></Card>
        </Col>
      </Row>
    </HomeLayout>
  );
}

export default DesignsPage;
