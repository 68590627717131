import React from "react";
import CardPreviewHeader from "./components/header";
import "./card-preview.scss";
import CardPreviewTitleHeader from "./components/title-header";
import CardPreviewSocialMedia from "./components/social-media";
import CardPreviewGalleries from "./components/galleries";
import CardPreviewBankDetails from "./components/bank-details";
import CardPreviewProductService from "./components/product-service";
import { Card } from "antd";
import CardPreviewbadge from "./components/badge";
import { getValue } from "@utils/lodash";
import CardCompanyDocuments from "./components/company-documents";
import CardGoogleMapSection from "./components/google-map";
import CardContactUsForm from "./components/contact-us";
import CardUserDescription from "./components/user-description";
function Design1Page(props: any) {
  const { request } = props;
  console.log(request);
  return (
    <div className="card_preview_container ms-1">
      <CardPreviewHeader request={request} />
      <div className="position-relative">
        <Card className="card_preview_title_header_container">
          <CardPreviewTitleHeader request={request} />
          {getValue(request, `aboutInfo.badge_logos.length`, 0) > 0 && (
            <CardPreviewbadge request={request} />
          )}
          {getValue(request, `socialMediaLinks.length`, 0) > 0 && (
            <CardPreviewSocialMedia request={request} />
          )}

          {getValue(request, `settings.show_aboutus_section`, false) &&
            getValue(request, `userInfo.user_info`, "") &&
            getValue(request, `userInfo.user_info`, "") !== `<p></p>\n` && (
              <CardUserDescription
                text={getValue(request, `userInfo.user_info`, "")}
              />
            )}
          {getValue(request, `productServices.length`, 0) > 0 &&
            getValue(
              request,
              `settings.show_product_service_section`,
              false
            ) && <CardPreviewProductService request={request} />}
          {getValue(request, `galleries.length`, 0) > 0 &&
            getValue(request, `settings.show_gallery_section`, false) && (
              <CardPreviewGalleries request={request} />
            )}
          {getValue(request, `settings.show_payment_section`, false) && (
            <CardPreviewBankDetails request={request} />
          )}
          {getValue(request, `aboutInfo.documents.length`, 0) > 0 && (
            <CardCompanyDocuments request={request} />
          )}
          {getValue(request, `settings.show_maps_section`, false) &&
            getValue(request, `aboutInfo.google_maps_link`, "") && (
              <CardGoogleMapSection
                link={getValue(request, `aboutInfo.google_maps_link`, "")}
              />
            )}
          {getValue(request, `settings.show_contact_section`, false) && (
            <CardContactUsForm request={request} />
          )}
        </Card>
      </div>
    </div>
  );
}

export default Design1Page;
