import HomeLayout from "@layouts/HomeLayout";
import { Badge, Button, Card, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  CaretRightOutlined,
  CreditCardOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useNavigation } from "@context/navigation-provider";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
import { getSpecificSubscription } from "@services/subscriptions.service";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import "./index.scss";
function SettingsSubscriptionPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const { userInfo } = useNavigation();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(userInfo, `subscription_id`, "")) {
      getData();
    }
  }, [getValue(userInfo, `subscription_id`, "")]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [info, setInfo] = useState({});
  const getData = async () => {
    try {
      let resp = await getSpecificSubscription(
        getValue(userInfo, `subscription_id`, "")
      );
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        console.log(resp);
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  console.log(userInfo);
  return (
    <HomeLayout>
      <Space>
        <Typography.Title level={4}>Subscription</Typography.Title>
      </Space>

      <Card bordered={true} className="mt-3 w-50">
        <div className="d-flex justify-content-between aligm-items-center">
          <div className="d-flex align-items-center">
            <CreditCardOutlined style={{ fontSize: "20px" }} />
            <div className="ms-3">
              <Typography.Text>ZippyCards</Typography.Text>
              {getValue(userInfo, `subscription_plan`, "") && (
                <Typography.Text className="ms-2 color_primary">
                  {formatString(getValue(userInfo, `subscription_plan`, ""))}{" "}
                  Plan
                </Typography.Text>
              )}
              <div>
                <Typography.Text>
                  {getValue(userInfo, `is_paid`, false) ? "Paid" : "No Billing"}
                </Typography.Text>
              </div>
            </div>
          </div>
          <div>
            {!getValue(userInfo, `is_paid`, false) ? (
              <Button
                icon={<CaretRightOutlined />}
                block
                iconPosition={"end"}
                onClick={() => navigate(`/pricing`)}
              >
                {/* Upgrade */}
                Check Plans
              </Button>
            ) : (
              <Button
                icon={<CaretRightOutlined />}
                block
                iconPosition={"end"}
                onClick={() => navigate(`/pricing`)}
              >
                Check Plans
              </Button>
            )}
          </div>
        </div>
      </Card>
      <Typography.Paragraph className="mt-4 w-50">
        With ZippyCards, you can personalize your experience and strengthen your
        branding with web app customization, custom QR Code logos, and much
        more!
      </Typography.Paragraph>
      {getValue(userInfo, `subscription_id`, "") && (
        <>
          <Typography.Title level={3}>Plan Details</Typography.Title>
          <div className="d-flex  w-50 mt-3 align-items-center">
            <h6 className="header_text__16 w-25">Start Date</h6>
            <div className="d-flex align-items-center">
              <h6 className="mx-2 header_text__16 color_primary">
                : {convertCurrentDateWithTime(getValue(info, `start_date`, ""))}
              </h6>
            </div>
          </div>
          <div className="d-flex  w-50 mt-3 align-items-center">
            <h6 className="header_text__16 w-25">End Date</h6>
            <div className="d-flex align-items-center">
              <h6 className="mx-2 header_text__16 color_primary">
                : {convertCurrentDateWithTime(getValue(info, `end_date`, ""))}
              </h6>
            </div>
          </div>
          <div className="d-flex  w-50 mt-3 align-items-center">
            <h6 className="header_text__16 w-25">Plan Name</h6>
            <div className="d-flex align-items-center">
              <h6 className="mx-2 header_text__16 color_primary">
                : {formatString(getValue(userInfo, `subscription_plan`, ""))}
              </h6>
            </div>
          </div>
          <div className="d-flex  w-50 mt-3 align-items-center">
            <h6 className="header_text__16 w-25">Plan Type</h6>
            <div className="d-flex align-items-center">
              <h6 className="mx-2 header_text__16 color_primary">
                : {formatString(getValue(info, `plan_type`, ""))}
              </h6>
            </div>
          </div>
        </>
      )}
    </HomeLayout>
  );
}

export default SettingsSubscriptionPage;
