import { createContact } from "@services/contacts.service";
import { getValue } from "@utils/lodash";
import type { FormProps } from "antd";
import { Button, Card, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import { useParams } from "react-router-dom";

type FieldType = {
  full_name: string;
  email?: string;
  phone_number?: string;
  message?: string;
};

function CardContactUsForm(props: any) {
  const params = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("Success:", values);
    if (getValue(params, `id`, "")) {
      try {
        setSubmitLoading(true);
        const resp = await createContact({
          full_name: values.full_name,
          email: values.email,
          phone_number: values.phone_number,
          message: values.message,
          card_id: getValue(params, `id`, ""),
        });
        if (resp) {
          message.success("Submitted Successfully");
          form.resetFields();
        }
      } catch (error) {
        console.error("Error during login:", error);
      } finally {
        setSubmitLoading(false);
      }
    } else {
      message.error("Please create card to continue");
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const [position, setPosition] = useState<"start" | "end">("end");

  return (
    <>
      <h6 className="header_text__22 text-center mt-4 mb-4 color_desc">
        Contact
      </h6>
      <div>
        <Card>
          <Form
            form={form}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            className="w-100"
          >
            <Form.Item<FieldType>
              label="Full Name"
              name="full_name"
              rules={[
                { required: true, message: "Please input your Full Name!" },
              ]}
            >
              <Input
                style={{
                  height: "44px",
                  border: `1px solid #dadce0`,
                }}
                placeholder="Enter Full Name"
              />
            </Form.Item>

            <Form.Item<FieldType>
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                style={{
                  height: "44px",
                  border: `1px solid #dadce0`,
                }}
                placeholder="Enter Email"
              />
            </Form.Item>

            <Form.Item<FieldType> label="Phone Number" name="phone_number">
              <PhoneInput placeholder="Enter number" defaultCountry="in" />
            </Form.Item>

            <Form.Item<FieldType> label="Message" name="message">
              <TextArea
                style={{
                  height: "44px",
                  border: `1px solid #dadce0`,
                }}
                placeholder="Enter Message"
              />
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-between align-items-center">
                {submitLoading ? (
                  <Button
                    type="primary"
                    loading
                    iconPosition={position}
                    size="large"
                    style={{
                      background: getValue(
                        props,
                        `request.settings.theme_color`,
                        ""
                      ),
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitLoading}
                    size="large"
                    style={{
                      background: getValue(
                        props,
                        `request.settings.theme_color`,
                        ""
                      ),
                    }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default CardContactUsForm;
