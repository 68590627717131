import { getValue } from "@utils/lodash";
import React from "react";

function Design2SvgComponent(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 72 72"
        focusable="false"
        className="chakra-icon chakra-icon css-1kqumke"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "34"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "34"
        }
      >
        <g clip-path="url(#a)">
          <rect fill="#fff" height="72" rx="16" width="72"></rect>
          <g
            clip-path="url(#b)"
            fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#fff"
            }
          >
            <path d="M0-29.25h72v72.512H0z"></path>
            <path d="M0 32.184v4.88c13.344 7.171 24 7.605 40.224-.83 16.224-8.436 24-7.34 31.776-5.4V29.57c-17.856-5.99-32.352 5.845-43.584 8.798C17.184 41.319 9.888 39.21 0 32.184Z"></path>
          </g>
        </g>
        <defs>
          <clipPath id="a">
            <rect fill="#fff" height="72" rx="16" width="72"></rect>
          </clipPath>
          <clipPath id="b">
            <path d="M0-29.25h72v72.576H0z" fill="#fff"></path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default Design2SvgComponent;
