import { QueryRequestHelper } from "@common/query-request-helper";
import HomeLayout from "@layouts/HomeLayout";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Space,
  Tag,
  Badge,
  Switch,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getAllEnquiries } from "@services/enquiry.service";
import { getAllUsers, updateSpecificUser } from "@services/users.service";
import Loader from "more/loader";

function UsersPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);

  // Table columns
  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text: any, record: any) => record.first_name,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      render: (text: any, record: any) => record.last_name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => new Date(created_at).toLocaleString(),
    },
    {
      title: "Status",
      key: "action",
      render: (_: any, record: any) => (
        <Switch
          checked={getValue(record, `is_active`, false)}
          onChange={() =>
            handleUpdateUser(
              getValue(record, `id`, ""),
              !getValue(record, `is_active`, false)
            )
          }
        />
      ),
    },
  ];
  // Delete record function
  const handleDelete = (id: any) => {
    // setDataSource(dataSource.filter((item) => item.id !== id));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [selectionType, setSelectionType] = useState<any>("checkbox");
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [users, setusers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllUsers(queryRequest);
      if (resp) {
        setusers(getValue(resp, `data.users`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, ""));
        setIsLoading(false);
        console.log(resp);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleUpdateUser = async (id: string, is_active: boolean) => {
    try {
      setSubmitLoading(true);
      let resp = await updateSpecificUser(id, {
        is_active: is_active,
      });
      if (resp) {
        setSubmitLoading(false);
        message.success(getValue(resp, `message`, ""));
        getData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: number, page_size: number) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
      limit: page_size,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  return (
    <HomeLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={users}
          rowKey="id"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          pagination={{
            current: page_no,
            pageSize: 10,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page, pageSize) => {
              handleChangePagination(page, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      )}
    </HomeLayout>
  );
}

export default UsersPage;
