export const allNavigationMenu = [
  {
    key: "1",
    label: "Home",
    name: "hode",
    path: "/",
  },
  {
    key: "2",
    label: "Cards",
    name: "cards",
    path: "/cards",
  },
  {
    key: "3",
    label: "Contacts",
    name: "contacts",
    path: "/contacts",
  },

  // {
  //   key: "4",
  //   label: "Designs",
  //   name: "designs",
  //   path: "/designs",
  // },
  // {
  //   key: "5",
  //   label: "Analytics",
  //   name: "anlytics",
  //   path: "/analytics",
  // },
  {
    key: "6",
    label: "Settings",
    name: "settings",
    path: "/settings",
    children: [
      {
        key: "7",
        label: "Account",
        name: "settings",
        path: "/accounts",
      },
      // {
      //   key: "8",
      //   label: "Export Contacts",
      //   name: "settings",
      //   path: "/settings/export-contacts",
      // },
      {
        key: "8",
        label: "Subscription",
        name: "settings",
        path: "/subscriptions",
      },
      {
        key: "9",
        label: "Pricing",
        name: "settings",
        path: "/pricing",
      },
   
      // {
      //   key: "10",
      //   label: "Branding",
      //   name: "settings",
      //   path: "/settings/branding",
      // },
    ],
  },
  {
    key: "11",
    label: "Users",
    name: "users",
    path: "/users",
  },
  {
    key: "12",
    label: "Enquiries",
    name: "enquiries",
    path: "/enquiries",
  },
];
