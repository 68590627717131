import { getValue } from "@utils/lodash";
import { Segmented } from "antd";
import React from "react";

function SegmentPage(props: any) {
  const { request, handleSegmentChange, key_value, title } = props;
  return (
    <div className="d-flex align-items-center">
      <p className="small_text__14 mt-3 w-25">{title}</p>
      <div className="">
        <Segmented
          options={[
            {
              label: (
                <div style={{ padding: 2 }}>
                  <div className="color_primary">Yes</div>
                </div>
              ),
              value: true,
            },
            {
              label: (
                <div style={{ padding: 2 }}>
                  <div className="color_desc">No</div>
                </div>
              ),
              value: false,
            },
          ]}
          value={getValue(request, `settings.${key_value}`, false)}
          onChange={(e) => handleSegmentChange(e, key_value)}
        />
      </div>
    </div>
  );
}

export default SegmentPage;
