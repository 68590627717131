import React from "react";
import { getValue } from "@utils/lodash";

function CardWebsiteIcon(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          clipRule="evenodd"
          d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM4.00087 18H15.0009V14H4.00087V18ZM15.0009 13H4.00087V9H15.0009V13ZM15.9996 18H19.9996V9H15.9996V18Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default CardWebsiteIcon;
