import { QueryRequestHelper } from "@common/query-request-helper";
import HomeLayout from "@layouts/HomeLayout";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { Table, Button, Popconfirm, Space, Tag, Badge, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllEnquiries,
  updateSpecificEnquiry,
} from "@services/enquiry.service";
import { CheckCircleOutlined } from "@ant-design/icons";
import Loader from "more/loader";
function EnquiriesPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);
  // Table columns
  const columns = [
    {
      title: "Contact Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text: any, record: any) => record.full_name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => new Date(created_at).toLocaleString(),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <div
          className="cursor-pointer"
          onClick={() =>
            handleUpdateEnquiries(
              getValue(record, `id`, ""),
              getValue(record, `status`, "") === "active"
                ? "inactive"
                : "active"
            )
          }
        >
          <CheckCircleOutlined
            style={{
              fontSize: "24px",
              color:
                getValue(record, `status`, "") === "active"
                  ? "#F7A31C"
                  : "lightgray",
            }}
          />
        </div>
      ),
    },
  ];
  // Delete record function
  const handleDelete = (id: any) => {
    // setDataSource(dataSource.filter((item) => item.id !== id));
  };

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [selectionType, setSelectionType] = useState<any>("checkbox");
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [enquiries, setEnquiries] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllEnquiries(queryRequest);
      if (resp) {
        setEnquiries(getValue(resp, `data.enquiries`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, ""));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleUpdateEnquiries = async (id: string, status: string) => {
    try {
      setSubmitLoading(true);
      let resp = await updateSpecificEnquiry(id, {
        status: status,
      });
      if (resp) {
        setSubmitLoading(false);
        message.success(getValue(resp, `message`, ""));
        getData();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: number, page_size: number) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
      limit: page_size,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  return (
    <HomeLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={columns}
          dataSource={enquiries}
          rowKey="id"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          pagination={{
            current: page_no,
            pageSize: 10,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page, pageSize) => {
              handleChangePagination(page, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      )}
    </HomeLayout>
  );
}

export default EnquiriesPage;
