import React, { useState } from "react";
import { Layout, theme } from "antd";
import "./layout.scss";
import SideMenu from "./components/Sidemenu";
import TopHeader from "./components/Header";
import { Footer, Header } from "antd/es/layout/layout";
import SideMenuLogo from "./components/Sidemenu/components/side-menu-logo";

const { Content } = Layout;

const LoginLayout = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <Header
        style={{ padding: 0, background: "white" }}
        className="d-flex justify-content-between align-items-center"
      >
        <SideMenuLogo collapsed={collapsed} />
      </Header>
      <Content
        style={{
          margin: "24px 16px",
          padding: 24,
          background: '#fff',
          borderRadius: borderRadiusLG,
        }}
        className="layout_container"
      >
        {children}
      </Content>
    </Layout>
  );
};

export default LoginLayout;
