import SettingsPage from "@pages/private/Settings";
import SettingsAccountsPage from "@pages/private/Settings/Accounts";
import SettingsBrandingsPage from "@pages/private/Settings/Branding";
import SettingsExportContactsPage from "@pages/private/Settings/ExportContacts";
import SettingsSubscriptionPage from "@pages/private/Settings/Subscription";

export const SettingsRoutes = [
  {
    path: "/settings",
    name: "settings",
    component: SettingsPage,
  },
  {
    path: "/accounts",
    name: "settings",
    component: SettingsAccountsPage,
  },
  {
    path: "/settings/branding",
    name: "settings",
    component: SettingsBrandingsPage,
  },
  {
    path: "/settings/export-contacts",
    name: "settings",
    component: SettingsExportContactsPage,
  },
  {
    path: "/subscriptions",
    name: "settings",
    component: SettingsSubscriptionPage,
  },
];
