import CustomMultipleUploadComponent from "@components/common/custom-multiple-upload";
import { getValue } from "@utils/lodash";
import { Form, Input, Typography, Upload } from "antd";

function CardCompanyInfoPage(props: any) {
  const { request, setRequest } = props;
  const handleChange = (e: any, key: any) => {
    const { name, value } = e.target;
    setRequest((prevState: any) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [name]: value,
      },
    }));
  };

  const handleUploadMultiLogos = (e: any) => {
    request.aboutInfo.documents.push(e);
    setRequest({ ...request });
  };

  const handleRemoveMultiLogos = (e: any) => {
    setRequest((prevState: any) => ({
      ...prevState,
      aboutInfo: {
        ...prevState["aboutInfo"],
        documents: e,
      },
    }));
  };

  const handleChangeDescription = (e: any, file: any) => {
    const newDescription = e.target.value;

    setRequest((prevState: any) => ({
      ...prevState,
      aboutInfo: {
        ...prevState["aboutInfo"],
        documents: request.aboutInfo.documents.map(
          (item: any, index: number) => {
            if (item.uid !== file.uid) return item;
            return {
              ...item,
              description: newDescription,
            };
          }
        ),
      },
    }));
  };
  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 12 }}
      layout="vertical"
      //   initialValues={{ size: componentSize }}
      //   onValuesChange={onFormLayoutChange}
      // style={{ maxWidth: 600 }}
    >
      <Form.Item label="Business Name" vertical>
        <Input
          size="large"
          name="business_name"
          value={getValue(request, `aboutInfo.business_name`, "")}
          onChange={(e: any) => handleChange(e, "aboutInfo")}
          placeholder="Enter Business Name"
        />
      </Form.Item>
      <Form.Item label="Website URL" vertical>
        <Input
          size="large"
          name="website_url"
          value={getValue(request, `aboutInfo.website_url`, "")}
          onChange={(e: any) => handleChange(e, "aboutInfo")}
          placeholder="Enter Website URL"
        />
      </Form.Item>
      <Form.Item label="Nature of Business" vertical>
        <Input
          size="large"
          name="nature_of_Business"
          value={getValue(request, `aboutInfo.nature_of_Business`, "")}
          onChange={(e: any) => handleChange(e, "aboutInfo")}
          placeholder="Enter Nature of Business"
        />
      </Form.Item>
      <Form.Item label="Year of Establishment" vertical>
        <Input
          size="large"
          name="year_of_establishment"
          value={getValue(request, `aboutInfo.year_of_establishment`, "")}
          onChange={(e: any) => handleChange(e, "aboutInfo")}
          placeholder="Enter Year of Establishment"
        />
      </Form.Item>

      <Form.Item label="GSTIN Number" vertical>
        <Input
          size="large"
          name="gstin_no"
          value={getValue(request, `aboutInfo.gstin_no`, "")}
          onChange={(e: any) => handleChange(e, "aboutInfo")}
          placeholder="Enter GSTIN Number"
        />
      </Form.Item>
      <Form.Item label="Google Maps Link" vertical>
        <Input
          size="large"
          name="google_maps_link"
          value={getValue(request, `aboutInfo.google_maps_link`, "")}
          onChange={(e: any) => handleChange(e, "aboutInfo")}
          placeholder="Enter Google Maps Link"
        />
      </Form.Item>
      <Form.Item label="Company Documents" vertical>
      <CustomMultipleUploadComponent
        imageUrls={getValue(request, `aboutInfo.documents`, [])}
        uploadSuccess={handleUploadMultiLogos}
        uploadRemove={handleRemoveMultiLogos}
        handleChangeDescription={handleChangeDescription}
      />
      </Form.Item>
    </Form>
  );
}

export default CardCompanyInfoPage;
