import MultipleUploadComponent from "@components/common/multiple-upload";
import { getValue } from "@utils/lodash";

const GalleriesPage = (props: any) => {
  const { request, setRequest } = props;
  const handleUploadMultiLogos = (e: any) => {
    request.galleries.push(e);
    setRequest({ ...request });
  };

  const handleRemoveMultiLogos = (e: any) => {
    setRequest({
      ...request,
      galleries: e,
    });
  };
  return (
    <>
      <div className="mt-3">
        <MultipleUploadComponent
          title={""}
          imageUrls={getValue(request, `galleries`, [])}
          uploadSuccess={handleUploadMultiLogos}
          uploadRemove={handleRemoveMultiLogos}
        />
      </div>
    </>
  );
};

export default GalleriesPage;
