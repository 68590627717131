import React from "react";
import { getValue } from "@utils/lodash";

function CardLocationIcon(props:any) {
  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          clipRule="evenodd"
          d="M5.8418 9.2625C5.8418 5.80023 8.64203 3 12.1043 3C15.5666 3 18.3668 5.80023 18.3668 9.2625C18.3668 13.9594 12.1043 20.8928 12.1043 20.8928C12.1043 20.8928 5.8418 13.9594 5.8418 9.2625ZM9.8675 9.26252C9.8675 10.4971 10.8695 11.4991 12.1041 11.4991C13.3387 11.4991 14.3407 10.4971 14.3407 9.26252C14.3407 8.02791 13.3387 7.02591 12.1041 7.02591C10.8695 7.02591 9.8675 8.02791 9.8675 9.26252Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default CardLocationIcon;
