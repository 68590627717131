import HomePage from "@pages/private/Dashboard";
import SettingsPlanPage from "@pages/private/Settings/Pricing/plans";

export const PricingRoutes = [
  {
    path: "/pricing",
    name: "Pricing",
    component: SettingsPlanPage,
  },
];
