import HomeLayout from "@layouts/HomeLayout";
import { getAllPlans } from "@services/subscriptions.service";
import { getValue } from "@utils/lodash";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  CaretRightOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  RightCircleFilled,
} from "@ant-design/icons";
// import "./index.scss";
import axios from "axios";
import { convertDateTime } from "@common/date-helpers";
import { useNavigation } from "@context/navigation-provider";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import EnquriyModal from "./enquriy-modal";
import { addEnquiry } from "@services/enquiry.service";

function SettingsPlanPage(props) {
  const navigate = useNavigate();
  const { userInfo, getUserInfoCallback } = useNavigation();
  console.log(userInfo);
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [planList, setPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getAllPlans("");
      if (resp) {
        setIsLoading(false);
        setPlanList(resp);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const { Text, Link } = Typography;
  const [loading, setLoading] = useState(false);
  const handleSubscription = async (plan_key) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscription`,
        {
          plan_type: type,
          plan_key: plan_key,
          start_date: convertDateTime(new Date()),
          is_active: true,
          is_free_trial: false,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("zippyToken"),
            "Content-Type": "application/json", // or other headers you need
            // Add any other headers as needed
          },
        }
      );
      console.log(response);
      const order = getValue(response, `data.data`, {});

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Replace with your Razorpay key ID
        amount: order.amount, // Amount in the smallest currency unit (e.g., paise)
        currency: order.currency,
        name: "ZippyCards",
        description: "Subscription Payment",
        order_id: order.id, // Razorpay order ID
        handler: async function (response) {
          const paymentResult = await axios.post(
            `${process.env.REACT_APP_API_URL}/subscription/verify`,
            {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("zippyToken"),
                "Content-Type": "application/json", // or other headers you need
                // Add any other headers as needed
              },
            }
          );

          if (paymentResult.data.success) {
            message.success("Payment successful!");
            getUserInfoCallback();
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
              navigate("/subscriptions");
            }, 2500);
          } else {
            message.error("Payment failed!");
          }
        },
        prefill: {
          name:
            getValue(userInfo, `first_name`, "") +
            " " +
            getValue(userInfo, `last_name`, ""),
          email: getValue(userInfo, `email`, ""),
          contact: getValue(userInfo, `phone_number`, ""),
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.log(error);
      console.error("Error creating subscription:", error);
      message.error(getValue(error, `response.data.message`, ""));
    }
  };

  const [type, setType] = useState("year");
  const handleToggle = (value) => {
    setType(value); // Update the toggle state
  };

  /* -------------------------------------------------------------------------- */
  /*                               Enquiry section                              */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };


  return (
    <HomeLayout>
      <section>
        <div className="pricing-section">
          <div className="background">
            <img src="background-image.jpg" alt="Background" />
          </div>
          <div className="toggle">
            <button
              className={type === "month" ? "active" : ""}
              onClick={() => handleToggle("month")}
            >
              Monthly
            </button>
            <button
              className={type === "year" ? "active" : ""}
              onClick={() => handleToggle("year")}
            >
              Annually
            </button>
          </div>
          <div className="pricing-list">
            {planList.map((item) => {
              return (
                <div className="pricing-item">
                  <div className="px-3 pt-3">
                    <div className="d-flex justify-content-between">
                      <h6>{getValue(item, `plan_name`, "")}</h6>
                      <span className="color_desc ms-3 small_text__12">
                        {" "}
                        {getValue(item, `allowed_cards`, "") == -1
                          ? "Unlimited"
                          : getValue(item, `allowed_cards`, "")}{" "}
                        card(s){" "}
                      </span>
                    </div>
                    <div className="p-2">
                      {getValue(item, `discounted_yearly_price`, "") === -1 ||
                        getValue(item, `discounted_monthly_price`, "") === -1 ? (
                        <>
                          <Typography.Title level={2}>
                            Contact Us
                          </Typography.Title>
                        </>
                      ) : (
                        <>
                          {type === "month" ? (
                            <Space>
                              <Text delete type="danger">
                                Rs{" "}
                                {getValue(item, `original_monthly_price`, "")}
                              </Text>
                              <Typography.Title level={2}>
                                Rs.
                                {getValue(item, `discounted_monthly_price`, "")}
                              </Typography.Title>
                              / month
                            </Space>
                          ) : (
                            <Space>
                              <Text delete type="danger">
                                Rs {getValue(item, `original_yearly_price`, "")}
                              </Text>
                              <Typography.Title level={2}>
                                Rs.
                                {getValue(item, `discounted_yearly_price`, "")}
                              </Typography.Title>
                              / Year
                            </Space>
                          )}
                        </>
                      )}
                    </div>

                    <p>{getValue(item, `plan_description`, "")}</p>
                  </div>
                  <ul className="options">
                    {getValue(item, `options`, []).map((ite) => {
                      return (
                        <div className="d-flex align-items-start">
                          <div>
                            <CaretRightOutlined style={{ color: "gray" }} />
                          </div>
                          <p className="small_text__14 ms-3">
                            {getValue(ite, `name`, "")}
                          </p>
                        </div>
                      );
                    })}
                  </ul>
                  {getValue(item, `discounted_yearly_price`, "") === -1 ||
                    getValue(item, `discounted_monthly_price`, "") === -1 ? (
                    <div
                      className="d-flex justify-content-center align-items-center mb-2 cursor-pointer"
                      onClick={() => handleModal()}
                    >
                      <p className="mt-3 pricing_text">
                        Contact Us{" "}
                        <span className="ms-2">
                          <InfoCircleFilled />
                        </span>
                      </p>
                    </div>
                  ) : getValue(item, `plan_key`, "") === "FREE" ? (
                    <div className="d-flex justify-content-center align-items-center mb-4 cursor-pointer ">
                      <p className="mt-3 pricing_text">Free tier</p>
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center mb-4 cursor-pointer"
                      onClick={() =>
                        handleSubscription(getValue(item, `plan_key`, ""))
                      }
                    >
                      <p className="mt-3 pricing_text">
                        Choose Us{" "}
                        <span className="ms-2">
                          <RightCircleFilled />
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/* <div className="pricing-item">
              <h3>Pro Plan</h3>
              <h4>{isMonthly ? "$20/month" : "$200/year"}</h4>
              <p>For professionals looking for more features.</p>
              <ul className="options">
                <li>Option 1</li>
                <li>Option 2</li>
                <li>Option 3</li>
              </ul>
              <a href="#" className="button">
                Choose Plan
                <svg viewBox="0 0 24 24">
                  <path d="M12 2L16 6H8L12 2z" />
                </svg>
              </a>
            </div> */}

          {/* <div className="pricing-item">
              <h3>Enterprise Plan</h3>
              <h4>{isMonthly ? "$30/month" : "$300/year"}</h4>
              <p>For enterprises needing advanced solutions.</p>
              <ul className="options">
                <li>Option 1</li>
                <li>Option 2</li>
                <li>Option 3</li>
              </ul>
              <a href="#" className="button">
                Choose Plan
                <svg viewBox="0 0 24 24">
                  <path d="M12 2L16 6H8L12 2z" />
                </svg>
              </a>
            </div> */}
        </div>
      </section>
      {loading && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
      <EnquriyModal
        handleModal={handleModal}
        request={request}
        setRequest={setRequest}
        isOpen={isOpen}
      />
    </HomeLayout>
  );
}

export default SettingsPlanPage;
