import { MessageOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";

function isVideo(url: string) {
  return url && (url.endsWith(".mp4") || url.includes("video"));
}

function CardPreviewTitleHeader2(props: any) {
  const { request } = props;
  const imageUrl = getValue(request, "aboutInfo.business_logo[0].url", "");

  return (
    <>
      <div className="profile_logo_container__2">
        {imageUrl && (
          <div className="card_preview_header_logo__2">
            {isVideo(imageUrl) ? (
              <video
                src={imageUrl}
                controls
                className="card_preview_header_logo_video"
              />
            ) : (
              <img src={imageUrl} alt="Business Logo" />
            )}
          </div>
        )}
      </div>
      <div className="card_preview_title_header_container__2">
        <div className="identity__2">
          {getValue(request, `userInfo.user_name`, "") && (
            <h6 className="header">
              {getValue(request, `userInfo.user_name`, "")}
              {getValue(request, `userInfo.maiden_name`, "") && (
                <span className="ms-2">
                  {getValue(request, `userInfo.maiden_name`, "")}
                </span>
              )}
            </h6>
          )}
          <p className="small_text__16">
            {getValue(request, `userInfo.accreditations`, "")}
          </p>
        </div>
      </div>
      {(getValue(request, `userInfo.prefered_name`, "") ||
        getValue(request, `userInfo.pronouns`, "")) && (
        <div className="card_preview_preferred_container mt-3">
          <MessageOutlined />
          <p className="text mt-3">
            Goes by {getValue(request, `userInfo.prefered_name`, "")}{" "}
            {getValue(request, `userInfo.pronouns`, "") && (
              <span className="ms-1">
                {getValue(request, `userInfo.pronouns`, "")}
              </span>
            )}
          </p>
        </div>
      )}
    </>
  );
}

export default CardPreviewTitleHeader2;
