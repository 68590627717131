import React from "react";
import { getValue } from "@utils/lodash";

function CardDiscordIcon(props:any) {
  return (
    <div>
      <svg
        viewBox="0 0 30 21"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          d="M25.9091 1.625C25.9091 1.625 22.7727 -0.7375 19.0909 -1L18.75 -0.34375C22.0909 0.44375 23.5909 1.55938 25.2273 2.9375C22.5 1.55938 19.7727 0.3125 15 0.3125C10.2273 0.3125 7.5 1.55938 4.77273 2.9375C6.34091 1.55938 8.18182 0.3125 11.25 -0.34375L10.9091 -1C7.02273 -0.671875 4.09091 1.625 4.09091 1.625C4.09091 1.625 0.613636 6.48125 0 16.0625C3.54545 20 8.86364 20 8.86364 20L9.95455 18.5562C8.04545 17.9 5.93182 16.7844 4.09091 14.75C6.27273 16.3906 9.61364 18.0312 15 18.0312C20.3864 18.0312 23.7273 16.3906 25.9091 14.75C24.0682 16.7844 21.8864 17.9 20.0455 18.5562L21.1364 20C21.1364 20 26.4545 20 30 16.0625C29.3864 6.48125 25.9091 1.625 25.9091 1.625ZM10.5682 13.4375C9.27273 13.4375 8.18182 12.2563 8.18182 10.8125C8.18182 9.36875 9.27273 8.1875 10.5682 8.1875C11.8636 8.1875 12.9545 9.36875 12.9545 10.8125C12.9545 12.2563 11.8636 13.4375 10.5682 13.4375ZM19.4318 13.4375C18.1364 13.4375 17.0455 12.2563 17.0455 10.8125C17.0455 9.36875 18.1364 8.1875 19.4318 8.1875C20.7273 8.1875 21.8182 9.36875 21.8182 10.8125C21.8182 12.2563 20.7273 13.4375 19.4318 13.4375Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
        ></path>
      </svg>
    </div>
  );
}

export default CardDiscordIcon;
