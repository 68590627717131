import { get, post, patch, Delete } from "./helpers/http-handler";

export const getAllContacts = (queryRequest: any) =>
  get(`${process.env.REACT_APP_API_URL}/contacts?${queryRequest}`);

export const getSpecificContact = (id: string) =>
  get(`${process.env.REACT_APP_API_URL}/contacts/${id}`);

export const createContact = (payload: any) =>
  post(`${process.env.REACT_APP_API_URL}/contacts`, payload);

export const updateContact = (id: string, payload: any) =>
  patch(`${process.env.REACT_APP_API_URL}/contacts/${id}`, payload);

export const deleteContact = (id: string) =>
  Delete(`${process.env.REACT_APP_API_URL}/contacts/${id}`);
