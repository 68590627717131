import { setValue } from "@utils/lodash";
import SegmentPage from "./helpers/segement";

function MorePage(props: any) {
  const { request, setRequest } = props;
  const handleSegmentChange = (value: any, key: string) => {
    const updatedRequest = { ...request };
    setValue(updatedRequest, `settings.${key}`, value);
    setRequest(updatedRequest);
  };
  return (
    <div>
      <SegmentPage
        title={"Show AboutUs"}
        key_value={"show_aboutus_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      />
      <SegmentPage
        title={"Show Payment"}
        key_value={"show_payment_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      />
      <SegmentPage
        title={"Show Gallery"}
        key_value={"show_gallery_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      />
      {/* <SegmentPage
        title={"Show Videos"}
        key_value={"show_video_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      /> */}
      <SegmentPage
        title={"Show Products/Service"}
        key_value={"show_product_service_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      />
      <SegmentPage
        title={"Show Maps"}
        key_value={"show_maps_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      />
        <SegmentPage
        title={"Show Contact Form"}
        key_value={"show_contact_section"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      />
      {/* <SegmentPage
        title={"Show Menu"}
        key_value={"show_menu"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      /> */}
      {/* <SegmentPage
        title={"Show Copyright Footer"}
        key_value={"show_copyright_footer"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      /> */}
      {/* <SegmentPage
        title={"Email Notifications"}
        key_value={"email_notification"}
        request={request}
        handleSegmentChange={handleSegmentChange}
      /> */}
    </div>
  );
}

export default MorePage;
