import React from "react";
import { getValue } from "@utils/lodash";

function CardWechatIcon(props:any) {
  return (
    <div>
      <svg
        viewBox="0 0 34 27"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          clipRule="evenodd"
          d="M30.4583 23.9381C32.5833 22.4072 34 20.1804 34 17.5361C34 12.9433 29.4667 9.18557 23.8 9.18557C18.1333 9.18557 13.6 12.9433 13.6 17.5361C13.6 22.1289 18.1333 25.8866 23.8 25.8866C24.9333 25.8866 26.0667 25.7474 27.2 25.4691H27.4833C27.625 25.4691 27.9083 25.4691 28.05 25.6082L30.3167 26.8608H30.4583C30.6 26.8608 30.7417 26.7217 30.7417 26.5825V26.3041L30.3167 24.634V24.3557C30.175 24.3557 30.3167 24.0773 30.4583 23.9381ZM12.325 0C5.525 0 0 4.45361 0 10.0206C0 13.0825 1.7 15.7268 4.25 17.6753C4.39167 17.8144 4.53333 18.0928 4.53333 18.3711V18.6495L3.96667 20.7371V21.0155C3.96667 21.2938 4.10833 21.433 4.39167 21.433L4.675 21.2938L7.36667 19.7629C7.50833 19.6237 7.65 19.4845 7.93333 19.4845H8.35833C9.63333 19.9021 10.9083 20.0412 12.325 20.0412H13.0333C12.75 19.2062 12.6083 18.3711 12.6083 17.5361C12.6083 12.5258 17.5667 8.35052 23.8 8.35052H24.5083C23.5167 3.61856 18.4167 0 12.325 0ZM20.4 16.2835C19.6917 16.2835 18.9833 15.7268 18.9833 14.8918C18.9833 14.1959 19.55 13.5 20.4 13.5C21.1083 13.5 21.8167 14.0567 21.8167 14.8918C21.675 15.7268 21.1083 16.2835 20.4 16.2835ZM27.2 16.2835C26.4917 16.2835 25.7833 15.7268 25.7833 14.8918C25.7833 14.1959 26.35 13.5 27.2 13.5C27.9083 13.5 28.6167 14.0567 28.6167 14.8918C28.475 15.7268 27.9083 16.2835 27.2 16.2835ZM8.21667 8.48969C7.36667 8.48969 6.51667 7.79381 6.51667 6.81959C6.51667 5.98454 7.225 5.14948 8.21667 5.14948C9.20833 5.14948 9.91667 5.84536 9.91667 6.81959C9.775 7.79381 9.06667 8.48969 8.21667 8.48969ZM16.4333 8.48969C15.5833 8.48969 14.7333 7.79381 14.7333 6.81959C14.7333 5.98454 15.4417 5.14948 16.4333 5.14948C17.425 5.14948 18.1333 5.84536 18.1333 6.81959C17.9917 7.79381 17.2833 8.48969 16.4333 8.48969Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default CardWechatIcon;
