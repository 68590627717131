import PageLayout from "@layouts/PageLayout";
import React from "react";
import { Button, Tabs, Tooltip } from "antd";
import {
  DownloadOutlined,
  EditOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

function EditCardsPage(props: any) {
  const items: any = [
    {
      key: 1,
      label: `Share`,
      children: `Share`,
      icon: <ShareAltOutlined />,
    },
    {
      key: 2,
      label: `Settings`,
      children: `Settings`,
      icon: <SettingOutlined />,
    },
  ];
  return (
    <PageLayout>
      <div className="d-flex m-2 justify-content-between">
        <div className="card_preview_section"></div>
        <div className="card_edit_section p-3">
          <Tabs defaultActiveKey="2" items={items} />
          <div className="card_download_button">
            <Tooltip title="Download">
              <Button
                icon={<DownloadOutlined />}
                href="https://www.google.com"
                iconPosition={"start"}
              />
            </Tooltip>
          </div>
          <div className="card_edit_button">
            <Button
              type="primary"
              icon={<EditOutlined />}
              size={"small"}
              className="p-2"
            >
              Edit
            </Button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default EditCardsPage;
