import { Card, Col, Row, Typography, ColorPicker } from "antd";
import { useState } from "react";
import SingleUploadComponent from "@components/common/single-upload";
import { getValue } from "@utils/lodash";
import MultipleUploadComponent from "@components/common/multiple-upload";
import DesignOneSvgComponent from "@assets/svg/design1";
import Design2SvgComponent from "@assets/svg/design2";
import Design3SvgComponent from "@assets/svg/design3";

function CardDisplayPage(props: any) {
  const { request, setRequest } = props;
  const { Title, Paragraph } = Typography;
  const designs = [
    {
      value: "design_1",
      label: "Classic",
      icon: (
        <DesignOneSvgComponent
          color={getValue(request, `settings.theme_color`, "")}
        />
      ),
    },
    {
      value: "design_2",
      label: "Flat",
      icon: (
        <Design2SvgComponent
          color={getValue(request, `settings.theme_color`, "")}
        />
      ),
    },
    {
      value: "design_3",
      label: "Sleek",
      icon: (
        <Design3SvgComponent
          color={getValue(request, `settings.theme_color`, "")}
        />
      ),
    },
    // { value: "DESIGN_3", label: "Design 3" },
    // { value: "DESIGN_4", label: "Design 4" },
    // { value: "DESIGN_5", label: "Design 5" },
    // { value: "DESIGN_6", label: "Design 6" },
  ];

  const handleUploadMultiLogos = (e: any) => {
    console.log(e);
    request.aboutInfo.badge_logos.push(e);
    setRequest({ ...request });
  };

  const handleRemoveMultiLogos = (e: any) => {
    setRequest({
      ...request,
      aboutInfo: {
        ...request.aboutInfo,
        badge_logos: e,
      },
    });
  };

  function rgbToHex(r: number, g: number, b: number) {
    return "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
  }

  return (
    <div>
      <div>
        <Title level={5}>Design</Title>
        <Row>
          {designs.map((item: object, index: number) => {
            return (
              <Col
                className={`card_design_container cursor-pointer ${
                  getValue(item, `value`, "") ===
                  getValue(request, `card_design`, "")
                    ? "active"
                    : ""
                }`}
                key={index}
                onClick={() =>
                  setRequest({
                    ...request,
                    card_design: getValue(item, `value`, ""),
                  })
                }
              >
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <Card bordered={true} className="card">
                    <div
                      style={{
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    >
                      {getValue(item, `icon`, "")}
                    </div>
                  </Card>
                </div>
                <Paragraph className="text-center">
                  {getValue(item, `label`, "")}
                </Paragraph>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="mt-3">
        <Title level={5}>Theme Color</Title>
        <ColorPicker
          value={getValue(request, `settings.theme_color`, "")}
          onChange={(e: any) => {
            const { r, g, b } = e.metaColor;
            const hexColor = rgbToHex(r, g, b);
            setRequest({
              ...request,
              settings: {
                ...request.settings,
                theme_color: hexColor,
              },
            });
          }}
        />
      </div>
      <div className="mt-3">
        <SingleUploadComponent
          title={"Profile Banner"}
          imageUrls={getValue(request, `userInfo.profile_logo`, "")}
          uploadSuccess={(e: any) => {
            setRequest({
              ...request,
              userInfo: {
                ...request.userInfo,
                profile_logo: [e],
              },
            });
          }}
          uploadRemove={(e: any) => {
            console.log(e);
            setRequest({
              ...request,
              userInfo: {
                ...request.userInfo,
                profile_logo: e,
              },
            });
          }}
        />
      </div>
   
      <div className="mt-3">
        <SingleUploadComponent
          title={"Profile Logo"}
          imageUrls={getValue(request, `aboutInfo.business_logo`, "")}
          uploadSuccess={(e: any) => {
            setRequest({
              ...request,
              aboutInfo: {
                ...request.aboutInfo,
                business_logo: [e],
              },
            });
          }}
          uploadRemove={(e: any) => {
            setRequest({
              ...request,
              aboutInfo: {
                ...request.aboutInfo,
                business_logo: e,
              },
            });
          }}
        />
      </div>
      <div className="mt-3">
        <MultipleUploadComponent
          title={"Badges"}
          imageUrls={getValue(request, `aboutInfo.badge_logos`, [])}
          uploadSuccess={handleUploadMultiLogos}
          uploadRemove={handleRemoveMultiLogos}
        />
      </div>
    </div>
  );
}

export default CardDisplayPage;
