import { getFirstLetterOfWord } from "@common/text-helpers";
import SingleUploadComponent from "@components/common/single-upload";
import { useNavigation } from "@context/navigation-provider";
import HomeLayout from "@layouts/HomeLayout";
import {
  deleteSpecificUser,
  updateSpecificUser,
} from "@services/users.service";
import { getValue } from "@utils/lodash";
import { Button, Input, message, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";

function SettingsAccountsPage(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    profile_pic: [],
  });

  function isFormEmpty() {
    // Loop through each field in the request object
    for (let key in request) {
      if (request[key]) {
        return false; // Return false if any field is not empty
      }
    }
    return true; // Return true if all fields are empty
  }

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const { userInfo, getUserInfoCallback } = useNavigation();

  useEffect(() => {
    if (getValue(userInfo, `id`, "")) {
      setRequest((prevState: any) => ({
        ...prevState,
        first_name: getValue(userInfo, `first_name`, ""),
        last_name: getValue(userInfo, `last_name`, ""),
        email: getValue(userInfo, `email`, ""),
        phone_number: getValue(userInfo, `phone_number`, ""),
        profile_pic: getValue(userInfo, `profile_pic`, []),
      }));
    }
  }, [userInfo]);
  const handleDelete = async () => {
    try {
      let resp = await updateSpecificUser(getValue(userInfo, `id`, ""), {
        is_active: false,
      });
      if (resp) {
        localStorage.clear();
        message.success("Account removed successfully");
        window.location.href = "/";
      }
    } catch (error) {}
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleUpdateUser = async () => {
    try {
      setSubmitLoading(true);
      let resp = await updateSpecificUser(getValue(userInfo, `id`, ""), {
        ...request,
        profile_pic: [{ url: getValue(request, `profile_pic[${0}].url`, "") }],
      });
      if (resp) {
        setSubmitLoading(false);
        message.success(getValue(resp, `message`, ""));
        getUserInfoCallback();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  return (
    <HomeLayout>
      <div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h6 className="header_text__22">Account</h6>
          <div className="">
            <Popconfirm
              title="Are you sure you want to delete account?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button danger>Delete Account</Button>
            </Popconfirm>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="mt-4 w-75">
            <div className="d-flex justify-content-center">
              <SingleUploadComponent
                listType="picture-circle"
                imageUrls={getValue(request, `profile_pic`, [])}
                uploadSuccess={(e: any) => {
                  setRequest({
                    ...request,
                    profile_pic: [e],
                  });
                }}
                uploadRemove={(e: any) => {
                  setRequest({
                    ...request,
                    profile_pic: e,
                  });
                }}
              />
            </div>
            <div className=" mt-4 d-flex justify-content-center">
              <div className="w-25">
                <h6 className="header_text__16">First Name</h6>
              </div>
              <div className="w-50">
                <Input
                  type="text"
                  size="large"
                  value={getValue(request, `first_name`, "")}
                  name="first_name"
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" mt-4 d-flex justify-content-center">
              <div className="w-25">
                <h6 className="header_text__16">Last Name</h6>
              </div>
              <div className="w-50">
                <Input
                  type="text"
                  size="large"
                  name="last_name"
                  value={getValue(request, `last_name`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" mt-4 d-flex justify-content-center">
              <div className="w-25">
                <h6 className="header_text__16">Email</h6>
              </div>
              <div className="w-50">
                <Input
                  type="text"
                  size="large"
                  name="email"
                  value={getValue(request, `email`, "")}
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className=" mt-4 d-flex justify-content-center">
              <div className="w-25">
                <h6 className="header_text__16">Phone Number</h6>
              </div>
              <div className="w-50">
                <PhoneInput
                  value={
                    getValue(request, `phone_number`, "")
                      ? getValue(request, `phone_number`, "")
                      : ""
                  }
                  defaultCountry="in"
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      phone_number: e,
                    })
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Button
                type="primary"
                loading={submitLoading}
                onClick={handleUpdateUser}
                size="large"
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
}

export default SettingsAccountsPage;
