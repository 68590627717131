// import ComfirmOrganization from "@pages/Private/Auth/confirm-org";

import ForgotPasswordPage from "@pages/public/ForgotPassword";
import LoginPage from "@pages/public/Login";
import ResetPasswordPage from "@pages/public/PasswordReset";
import RegisterPage from "@pages/public/Register";

//------------- Email configure -----------//
export const AuthRoutes = [
  {
    path: "/login",
    name: "Auth",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "Auth",
    component: RegisterPage,
  },
  {
    path: "/forgot-password",
    name: "Auth",
    component: ForgotPasswordPage,
  },
  {
    path: "/auth/set-password",
    name: "Auth",
    component: ResetPasswordPage,
  },
];
