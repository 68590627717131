import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { IconHelper } from "@common/icons-helper";
import { useNavigation } from "@context/navigation-provider";
import { allNavigationMenu } from "./navigation-menus";

function SideMenuItems(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  const navigate = useNavigate();
  const location = useLocation();

  const { clientNavigation, setClientNavigation, userInfo } = useNavigation();

  useEffect(() => {
    // Filter menu items based on the user ID
    const filteredNavigation =
      userInfo.id !== "02e6a6aa-d9a6-4617-9c1a-2c320e092bb6"
        ? allNavigationMenu.filter(
            (item) => item.key !== "11" && item.key !== "12"
          )
        : allNavigationMenu;

    setClientNavigation(
      filteredNavigation.map((item: any) => ({
        ...item,
        icon: IconHelper(getValue(item, "name", "")),
      }))
    );
  }, [userInfo]);

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleClickMenu = (e: any) => {
    let findCurrentTab: any;
    findCurrentTab = findMenuItem(allNavigationMenu, e.key);
    if (findCurrentTab) {
      navigate(`${findCurrentTab.path}`);
    }
  };

  const findMenuItem: any = (items: any[], key: any) => {
    for (let item of items) {
      if (getValue(item, `key`, "") === key) {
        return item;
      }
      if (item.children) {
        const found = findMenuItem(item.children, key);
        if (found) {
          return found;
        }
      }
    }
  };

  const selectedKey = () => {
    const findKey: any = (items: any) => {
      const currentPath = location.pathname.split("/")[1];
      for (let item of items) {
        const basePath = item.path.split("/")[1];
        if (currentPath === basePath) {
          return item.key;
        }
        if (item.children) {
          const foundKey = findKey(item.children);
          if (foundKey) {
            return foundKey;
          }
        }
      }
      return null;
    };

    const key = findKey(allNavigationMenu);
    return [key || "1"];
  };

  const findOpenKeys = (items: any, path: any) => {
    const basePath = path.split("/")[1];
    let openKeys: string[] = [];
    for (let item of items) {
      const itemBasePath = item.path.split("/")[1];
      if (basePath === itemBasePath || path.startsWith(item.path)) {
        openKeys.push(item.key);
      }
      if (item.children) {
        const childKeys = findOpenKeys(item.children, path);
        if (childKeys.length > 0) {
          openKeys.push(item.key, ...childKeys);
        }
      }
    }
    return openKeys;
  };

  const [openKeys, setOpenKeys] = useState<string[]>(() => {
    const initialOpenKeys = findOpenKeys(allNavigationMenu, location.pathname);
    return initialOpenKeys;
  });

  return (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={selectedKey()} 
      onClick={handleClickMenu}
      items={clientNavigation}
      openKeys={openKeys}
      onOpenChange={setOpenKeys}
    />
  );
}

export default SideMenuItems;
