import {
  Delete,
  get,
  patch,
  post,
  postWithMethod,
} from "./helpers/http-handler";

export const getAllEnquiries = (queryRequest: string) =>
  get(`${process.env.REACT_APP_API_URL}/enquiry?${queryRequest}`);

export const updateSpecificEnquiry = (id: string, payload: object) =>
  patch(`${process.env.REACT_APP_API_URL}/enquiry/${id}`, payload);

export const addEnquiry = (payload: object) =>
  post(`${process.env.REACT_APP_API_URL}/enquiry`, payload);
