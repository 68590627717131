import { get, post, patch, Delete } from "./helpers/http-handler";

export const getAllCards = (queryRequest: any) =>
  get(`${process.env.REACT_APP_API_URL}/cards?${queryRequest}`);

export const getSpecificCard = (id: string) =>
  get(`${process.env.REACT_APP_API_URL}/cards/${id}`);

export const createCard = (payload: any) =>
  post(`${process.env.REACT_APP_API_URL}/cards`, payload);

export const updateCard = (id: string, payload: any) =>
  patch(`${process.env.REACT_APP_API_URL}/cards/${id}`, payload);

export const deleteCard = (id: string) =>
  Delete(`${process.env.REACT_APP_API_URL}/cards/${id}`);

export const deleteGallery = (id: string) =>
  Delete(`${process.env.REACT_APP_API_URL}/cards/gallery/${id}`);

export const deleteProductService = (id: string) =>
  Delete(`${process.env.REACT_APP_API_URL}/cards/gallery/${id}`);
