import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Card, Col, Divider, Form, Input } from "antd";
import { authLogin } from "@services/auth.service";
import { getValue } from "@utils/lodash";
import LoginLayout from "@layouts/LoginLayout";
import "./index.scss";
import { useNavigate } from "react-router-dom";
type FieldType = {
  username?: string;
  password?: string;
  remember?: boolean;
};

const LoginPage: React.FC = () => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("Success:", values);
    try {
      setSubmitLoading(true);
      const resp = await authLogin({
        email: values.username,
        password: values.password,
      });
      if (resp) {
        localStorage.setItem(
          "zippyToken",
          getValue(resp, "data.access_token", "")
        );
        localStorage.setItem(
          "zippyUser",
          JSON.stringify(getValue(resp, "data.user", ""))
        );
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const [position, setPosition] = useState<"start" | "end">("end");
  const CustomTitle = ({ title }: { title: string }) => (
    <div className="d-flex justify-content-center align-items-center">
      <img src={"/logo-new.svg"} />
      <h6 className="header_text__18 mt-2">{title}</h6>
    </div>
  );
  const navigate = useNavigate();
  return (
    <LoginLayout>
      <div className="login_container">
        <Col md={8}>
          <Card
            // title="Login"
            bordered={true}
            style={{
              boxShadow: "#e3efff 0px 0px 0px 1px",
            }}
            title={<CustomTitle title="Login" />}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              className="w-100"
            >
              <Form.Item<FieldType>
                label="Email"
                name="username"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  style={{ height: "44px", border: "1px solid #cbdcf2" }}
                  placeholder="Enter your email"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  style={{ height: "44px", border: "1px solid #cbdcf2" }}
                  placeholder="Enter your password"
                />
              </Form.Item>

              <Form.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    title="Register"
                    className="ms-2 color_secondary_main small_text__14 cursor-pointer"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </p>
                  {submitLoading ? (
                    <Button
                      type="primary"
                      loading
                      iconPosition={position}
                      size="large"
                      style={{ background: "#38629A" }}
                    >
                      Login
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      size="large"
                      style={{ background: "#38629A" }}
                    >
                      Login
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Form>
            <Divider>
              <p className="small_text__14 d-flex mt-3">
                Don't have an account?{" "}
                <span
                  className="ms-2 color_secondary_main small_text__14 cursor-pointer"
                  onClick={() => navigate("/register")}
                >
                  Register
                </span>
              </p>
            </Divider>
          </Card>
        </Col>
      </div>
    </LoginLayout>
  );
};

export default LoginPage;
