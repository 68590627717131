import {
  Delete,
  get,
  patch,
  post,
  postWithMethod,
} from "./helpers/http-handler";

export const getAllUsers = (queryRequest: string) =>
  get(`${process.env.REACT_APP_API_URL}/users?${queryRequest}`);

export const getSpecificUser = (id: string) =>
  get(`${process.env.REACT_APP_API_URL}/users/${id}`);

export const getUserProfile = () => get(`${process.env.REACT_APP_API_URL}/users/me`);

export const updateSpecificUser = (id: string, payload: object) =>
  patch(`${process.env.REACT_APP_API_URL}/users/${id}`, payload);

export const deleteSpecificUser = (id: string) =>
  Delete(`${process.env.REACT_APP_API_URL}/users/${id}`);
