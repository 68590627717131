import { QueryRequestHelper } from "@common/query-request-helper";
import HomeLayout from "@layouts/HomeLayout";
import { deleteCard, getAllCards } from "@services/cards.service";
import { getValue } from "@utils/lodash";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  message,
  Popconfirm,
  Popover,
  QRCode,
  QRCodeProps,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import NoDataFound from "more/nodata";
import Loader from "more/loader";
import { snakeCase } from "lodash";

function CardsPage(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queyRequest = QueryRequestHelper(payload);
      let resp = await getAllCards(queyRequest);
      if (resp) {
        setList(getValue(resp, `data.cards`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleEditClick = (item: object) => {
    navigate(
      `/create-card/${getValue(item, `card_type`, "")}/${getValue(
        item,
        `id`,
        ""
      )}`
    );
  };

  const handleDelete = async (id: string) => {
    try {
      let resp = await deleteCard(id);
      if (resp) {
        message.success(getValue(resp, `message`, ""));
        getData();
      }
    } catch (error) {}
  };

  const handleView = async (id: string) => {
    window.open(`${process.env.REACT_APP_PREVIEW_URL}/${id}`, "_blank");
  };

  /* -------------------------------------------------------------------------- */
  /*                           QR Code Section                                  */
  /* -------------------------------------------------------------------------- */
  const [renderType, setRenderType] =
    React.useState<QRCodeProps["type"]>("canvas");

  function doDownload(url: string, fileName: string) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const downloadCanvasQRCode = (name: string, id: string) => {
    const canvas = document
      .getElementById(id)
      ?.querySelector<HTMLCanvasElement>("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      doDownload(url, `${snakeCase(name)}.png`);
    }
  };

  // Helper function to check if the URL is a video
  function isVideo(url: string) {
    return url && (url.endsWith(".mp4") || url.includes("video"));
  }

  return (
    <HomeLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-wrap gap-4">
          {getValue(list, `length`, 0) > 0 ? (
            list.map((item: object, index: number) => {
              return (
                <Badge.Ribbon
                  text={getValue(item, `card_type`, "")}
                  color={
                    getValue(item, `card_type`, "") === "personal"
                      ? "green"
                      : "pink"
                  }
                  key={index}
                >
                  <Card
                    style={{ width: 250 }}
                    cover={
                      getValue(item, `userInfo.profile_logo[0].url`, "") ? (
                        isVideo(
                          getValue(item, `userInfo.profile_logo[0].url`, "")
                        ) ? (
                          <video
                            src={getValue(
                              item,
                              `userInfo.profile_logo[0].url`,
                              ""
                            )}
                            className="card_image"
                            controls
                            // autoPlay
                            // loop
                            // muted
                          />
                        ) : (
                          <img
                            alt="Profile"
                            src={getValue(
                              item,
                              `userInfo.profile_logo[0].url`,
                              ""
                            )}
                            className="card_image"
                          />
                        )
                      ) : (
                        <div className="card_image"></div>
                      )
                    }
                    actions={[
                      <Tooltip title="Edit">
                        <EditOutlined
                          key="edit"
                          onClick={() => handleEditClick(item)}
                        />
                      </Tooltip>,
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(getValue(item, `id`, ""))}
                      >
                        <Tooltip title="Delete">
                          <DeleteOutlined />
                        </Tooltip>
                      </Popconfirm>,
                      <Tooltip title="View">
                        <EyeOutlined
                          key="settings"
                          onClick={() => handleView(getValue(item, `slug`, ""))}
                        />
                      </Tooltip>,
                      <Popover
                        content={
                          <div>
                            <div className="d-flex justify-content-end mb-2">
                              <Button
                                onClick={() =>
                                  downloadCanvasQRCode(
                                    getValue(item, `userInfo.user_name`, ""),
                                    `myqrcode${index}`
                                  )
                                }
                              >
                                <DownloadOutlined />
                              </Button>
                            </div>
                            <QRCode
                              type={renderType}
                              id={`myqrcode${index}`}
                              value={`${
                                process.env.REACT_APP_PREVIEW_URL
                              }/${getValue(item, `id`, "")}`}
                              bordered={false}
                              bgColor="#fff"
                              icon={
                                getValue(
                                  item,
                                  `userInfo.profile_logo[${0}].url`,
                                  ""
                                ) || undefined
                              }
                              // icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                            />
                          </div>
                        }
                      >
                        <QrcodeOutlined key="settings" />
                      </Popover>,
                    ]}
                  >
                    <Meta
                      avatar={
                        <Avatar
                          src={getValue(
                            item,
                            `aboutInfo.business_logo[${0}].url`,
                            ""
                          )}
                        />
                      }
                      title={getValue(item, `userInfo.user_name`, "")}
                      description={getValue(
                        item,
                        `userInfo.user_designation`,
                        ""
                      )}
                      style={{ height: "60px" }}
                    />
                  </Card>
                </Badge.Ribbon>
              );
            })
          ) : (
            <NoDataFound title={"No Cards Found"} />
          )}
        </div>
      )}
    </HomeLayout>
  );
}

export default CardsPage;
