import { getCardIconComponent } from "@common/card-icons-helper";
import { getValue } from "@utils/lodash";
import React from "react";

function BankCard(props: any) {
  const { theme_color, value, icon } = props;
  return (
    <div>
      <a className="d-flex align-items-center mt-2 cursor-pointer">
        <div
          className="icon_circle_container"
          style={{
            background: theme_color,
          }}
        >
          {icon}
        </div>
        <h6 className="ms-2 mt-2 header_text__16">{value}</h6>
      </a>
    </div>
  );
}

export default BankCard;
