import { getValue } from "@utils/lodash";
import React from "react";

function CardPreviewbadge(props: any) {
  const { request } = props;
  return (
    <div className="card_preview_badge_container">
      <div className="badges">
        {getValue(request, `aboutInfo.badge_logos`, []).map(
          (item: object, index: number) => {
            // if (index < 2)
              return (
                <div className="">
                  <img src={getValue(item, `url`, "")} alt="Profile Logo" />
                </div>
              );
          }
        )}
      </div>
    </div>
  );
}

export default CardPreviewbadge;
