import { Card, Typography, Space, Skeleton, Button } from "antd";
import {
  CopyFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import CardPreviewHeader from "../Designs/Design1/components/header";
import { useNavigate } from "react-router-dom";
import { formatString } from "@common/text-helpers";

const { Title, Text } = Typography;

const CustomCard = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="card_create_container">
      <Card
        style={{ width: 300, borderRadius: 10, overflow: "hidden" }}
        bodyStyle={{ padding: 0 }}
        className="cursor-pointer"
      >
        <CardPreviewHeader />
        <div style={{ padding: 20 }}>
          <Title level={4} className="mb-4">
            {getValue(props, `userInfo.first_name`, "")}{" "}
            {getValue(props, `userInfo.last_name`, "")}
          </Title>
          <Space direction="vertical" style={{ width: "100%" }}>
            {[...Array(2)].map((_, index) => (
              <Skeleton
                active={false}
                title={false}
                paragraph={{
                  rows: 2,
                  width: ["100%", "70%"],
                }}
                avatar={{ size: 32 }}
                className="custom-skeleton"
              />
            ))}
          </Space>
          <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
            {[...Array(4)].map((_, index) => (
              <div className="d-flex align-items-center mt-2">
                <CopyFilled style={{ color: "#F7A31C" }} />
                <div
                  style={{
                    background: "#f0f0f0",
                    height: "10px",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  className="ms-3"
                />
              </div>
            ))}
          </Space>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text strong>{getValue(props, `type`, "")}</Text>
            <Text type="secondary">Jan 3, 2024</Text>
          </div>
        </div>
      </Card>
      <div className=" mt-3">
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size={"large"}
          onClick={() =>
            navigate(`/create-card/${getValue(props, `type`, "")}`)
          }
          style={{ background: "#38629A" }}
        >
          New {formatString(getValue(props, `type`, ""))} Card
        </Button>
      </div>
    </div>
  );
};

export default CustomCard;
