import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, message, Button, Input, Modal, Image, Typography } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const CustomMultipleUploadComponent: React.FC<any> = (props) => {
  const {
    title,
    uploadSuccess,
    uploadRemove,
    imageUrls,
    handleChangeDescription,
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleCustomRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-images");

    try {
      const resp = await mediaUpload(formData);
      if (resp) {
        message.success("Image uploaded successfully!");
        const imageUrl = getValue(resp, `data.url`, "");

        const newFile = {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: imageUrl,
          description: "",
        };
        uploadSuccess(newFile);
        // onSuccess(resp);
      } else {
        throw new Error("Image upload failed.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      message.error("Image upload failed due to an error.");
      onError(error);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    uploadRemove(newFileList.filter((file) => file.status === "done"));
  };

  const handleRemove = (file: UploadFile) => {
    // setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    uploadRemove(imageUrls.filter((item: any) => item.uid !== file.uid));
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: UploadFile
  ) => {
    const newDescription = e.target.value;
    handleChangeDescription(e, file);
  };

  const uploadButton = (
    <Button style={{ border: 0, background: "none" }} type="link">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    </Button>
  );
  const { Title } = Typography;
  return (
    <>
      <div>
        <Title level={5}>{title}</Title>
        <Upload
          listType="picture-card"
          multiple
          fileList={imageUrls}
          beforeUpload={beforeUpload}
          customRequest={handleCustomRequest}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
          itemRender={(originNode, file) => (
            <div style={{ textAlign: "center" }}>
              {originNode}

              <Input
                placeholder="Enter description"
                value={getValue(file, `description`, "") || ""}
                onChange={(e) => handleDescriptionChange(e, file)}
                style={{ marginTop: 8 }}
              />
            </div>
          )}
        >
          {uploadButton}
        </Upload>

        {previewImage && (
          <Image
            wrapperStyle={{ display: "none" }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </div>
    </>
  );
};

export default CustomMultipleUploadComponent;
