import Sider from "antd/es/layout/Sider";
import React from "react";
import SideMenuLogo from "./components/side-menu-logo";
import SideMenuItems from "./components/side-menu-items";

function SideMenu(props: any) {
  const { collapsed,setCollapsed } = props;
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ background: "white" }}
      onCollapse={(value) => setCollapsed(value)}
    >
      <SideMenuLogo collapsed={collapsed} />
      <SideMenuItems />
    </Sider>
  );
}

export default SideMenu;
