import React from "react";
import { getValue } from "@utils/lodash";

function CardLinkIcon(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        focusable="false"
        className="chakra-icon chakra-icon css-ikjmp3"
        aria-hidden="true"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          d="M13.3297 4.02025C15.2265 2.12345 18.3018 2.12345 20.1986 4.02025C22.0954 5.91705 22.0954 8.99237 20.1986 10.8892L16.9851 14.1027C15.0883 15.9995 12.013 15.9995 10.1162 14.1027C9.72563 13.7122 9.72563 13.079 10.1162 12.6885C10.5067 12.298 11.1398 12.298 11.5304 12.6885C12.6461 13.8043 14.4551 13.8043 15.5709 12.6885L18.7844 9.47496C19.9002 8.35921 19.9002 6.55021 18.7844 5.43446C17.6687 4.31871 15.8597 4.31871 14.7439 5.43446L12.7071 7.47127C12.3166 7.86179 11.6834 7.86179 11.2929 7.47127C10.9024 7.08074 10.9024 6.44758 11.2929 6.05705L13.3297 4.02025Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
        ></path>
        <path
          d="M10.8891 20.198C8.99225 22.0948 5.91693 22.0948 4.02013 20.198C2.12333 18.3012 2.12333 15.2259 4.02013 13.3291L7.23367 10.1155C9.13047 8.21875 12.2058 8.21874 14.1026 10.1155C14.4931 10.5061 14.4931 11.1392 14.1026 11.5298C13.7121 11.9203 13.0789 11.9203 12.6884 11.5298C11.5726 10.414 9.76364 10.414 8.64788 11.5298L5.43434 14.7433C4.31859 15.8591 4.31859 17.668 5.43434 18.7838C6.55009 19.8996 8.35909 19.8996 9.47484 18.7838L11.4657 16.7929C11.8563 16.4024 12.4894 16.4024 12.88 16.7929C13.2705 17.1834 13.2705 17.8166 12.88 18.2071L10.8891 20.198Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "currentColor"
          }
        ></path>
      </svg>
    </div>
  );
}

export default CardLinkIcon;
