import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Card, Col, Divider, Form, Input } from "antd";
import { authLogin, authRegister } from "@services/auth.service";
import { getValue } from "@utils/lodash";
import LoginLayout from "@layouts/LoginLayout";
import AnchorLink from "antd/es/anchor/AnchorLink";
import { useNavigate } from "react-router-dom";
type FieldType = {
  first_name?: string;
  last_name?: string;
  username?: string;
  password?: string;
  remember?: boolean;
};

const RegisterPage: React.FC = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("Success:", values);
    try {
      setSubmitLoading(true);
      const resp = await authRegister({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.username,
        password: values.password,
      });
      if (resp) {
        localStorage.setItem(
          "zippyToken",
          getValue(resp, "data.access_token", "")
        );
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const [position, setPosition] = useState<"start" | "end">("end");
  const CustomTitle = ({ title }: { title: string }) => (
    <div className="d-flex justify-content-center align-items-center">
      <img src={"/logo-new.svg"} />
      <h6 className="header_text__18 mt-2">{title}</h6>
    </div>
  );
  const navigate = useNavigate();
  return (
    <LoginLayout>
      <div className="login_container">
        <Col md={8}>
          <Card
            title={<CustomTitle title="Register" />}
            bordered={true}
            style={{ boxShadow: "#e3efff 0px 0px 0px 1px" }}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              className="w-100"
            >
              <Form.Item<FieldType>
                label="First Name"
                name="first_name"
                rules={[
                  { required: true, message: "Please input your First Name!" },
                ]}
              >
                <Input
                  style={{
                    height: "44px",
                   border: "1px solid #cbdcf2"
                  }}
                  placeholder="Enter your First Name"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                ]}
              >
                <Input
                  style={{
                    height: "44px",
                   border: "1px solid #cbdcf2"
                  }}
                  placeholder="Enter your Last Name"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Email"
                name="username"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  style={{
                    height: "44px",
                   border: "1px solid #cbdcf2"
                  }}
                  placeholder="Enter your email"
                />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  style={{
                    height: "44px",
                   border: "1px solid #cbdcf2"
                  }}
                  placeholder="Enter your password"
                />
              </Form.Item>

              <Form.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="small_text__14 d-flex mt-3">
                    Already a user?{" "}
                    <span
                      className="ms-2 color_secondary_main small_text__14 cursor-pointer"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </span>
                  </p>
                  {submitLoading ? (
                    <Button
                      type="primary"
                      loading
                      iconPosition={position}
                      size="large"
                      style={{ background: "#38629A" }}
                    >
                      Register
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      size="large"
                      style={{ background: "#38629A" }}
                    >
                      Register
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </div>
    </LoginLayout>
  );
};

export default RegisterPage;
