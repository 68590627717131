import ArrowDownSvgComponent from "@assets/svg/arrow-down";
import ArrowUpSvgComponent from "@assets/svg/arrow-up";
import { getValue } from "@utils/lodash";
import { Image } from "antd";
import React, { useState } from "react";

function CardPreviewGalleries(props: any) {
  const { request } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleCancelPreview = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <h6 className="header_text__22 text-center mt-3 mb-3 color_desc">
        Gallery
      </h6>
      <div className="d-flex flex-wrap gap-1 ">
        {getValue(request, `galleries`, []).map((item: any, index: number) => {
          if (!showAll ? index < 3 : index > -1)
            return (
              <div
                key={index}
                className="image-container gallery_container"
                onClick={() => handlePreview(item)}
              >
                <Image
                  src={item.url || item.preview} // Display the image thumbnail
                  alt={`Image ${index + 1}`}
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    cursor: "pointer",
                    border: "1px solid #efefef",
                    borderRadius: "5px",
                  }}
                />
              </div>
            );
        })}
      </div>
      {getValue(request, `galleries.length`, 0) > 5 && (
        <div
          className="d-flex align-items-center justify-content-end cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          <p className="small_text__14 mt-3">View {showAll ? "Less" : "All"}</p>
          <div>
            {showAll ? (
              <ArrowUpSvgComponent size={24} color={"#000"} />
            ) : (
              <ArrowDownSvgComponent size={24} color={"#000"} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default CardPreviewGalleries;
