import {
  DollarOutlined,
  FileOutlined,
  IdcardOutlined,
  PieChartOutlined,
  ProductOutlined,
  QuestionOutlined,
  ReadOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const IconHelper = (name: any) => {
  switch (name) {
    case "dashboard":
    case "home":
      return <ProductOutlined />;
    case "cards":
      return <FileOutlined />;
    case "contacts":
      return <IdcardOutlined />;
    case "designs":
      return <ReadOutlined />;
    case "pricing":
      return <DollarOutlined />;
    case "enquiries":
      return <QuestionOutlined />;
    case "users":
      return <UsergroupAddOutlined />;
    case "settings":
      return <SettingOutlined />;
    case "anlytics":
      return <PieChartOutlined />;
    default:
      return <ProductOutlined />;
  }
};
