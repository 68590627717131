import { getValue } from "@utils/lodash";
import React from "react";

function DesignOneSvgComponent(props: any) {
  return (
    <div>
      <svg
        viewBox="0 0 72 72"
        focusable="false"
        className="chakra-icon chakra-icon css-1uz6nvy"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "34"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "34"
        }
      >
        <g clip-path="url(#clip0_1931_53838)">
          <path
            d="M0 -24H72V54H0V-24Z"
            fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#fff"
            }

          ></path>
          <path
            d="M72 72.5V39.18C44.16 29.9533 29.568 63.3176 0 41.7337V72.5H72Z"
            fill={"white"}
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_1931_53838">
            <rect fill="white" height="72" rx="16" width="72"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default DesignOneSvgComponent;
