import { getUserProfile } from "@services/users.service";
import { getValue } from "@utils/lodash";
import React, { createContext, useContext, useState, useEffect, useCallback } from "react";

const NavigationContext = createContext();

export function NavigationProvider({ children }) {
    const [clientNavigation, setClientNavigation] = useState([]);
    useEffect(() => {
        if (getValue(localStorage, `zippyToken`, "")) {
            getUserInfo()
        }
    }, []);

    /* -------------------------------------------------------------------------- */
    /*                               API Section                                  */
    /* -------------------------------------------------------------------------- */
    const [userInfo, setUserInfo] = useState({});
    const [remainingDays,setRemainingDays] = useState(0)
    const getUserInfo = async () => {
        let resp = await getUserProfile();
        if (resp) {
            const userData = getValue(resp, 'data', {});
            setUserInfo(userData);
            const now = new Date();
            // Get the created_at value from response
            const createdAt = getValue(resp, 'data.created_at', '');
            if (createdAt) {
                // Convert created_at to a Date object
                const createdDate = new Date(createdAt);
                // Calculate the target date (30 days from created_at)
                const target = new Date(createdDate);
                target.setDate(createdDate.getDate() + 30);
                // Calculate the remaining days
                const timeDiff = target - now;
                const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                setRemainingDays(daysRemaining);
            }
        }
    }
    /* -------------------------------------------------------------------------- */
    /*                               Callback Section                             */
    /* -------------------------------------------------------------------------- */


    const getUserInfoCallback = useCallback(() => {
        getUserInfo();
    }, []);

    return (
        <NavigationContext.Provider
            value={{
                clientNavigation,
                setClientNavigation,
                userInfo,
                remainingDays,
                getUserInfoCallback
            }}
        >{children}</NavigationContext.Provider>)
}

export function useNavigation() {
    return useContext(NavigationContext);
}
