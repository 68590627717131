import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Card, Col, Divider, Form, Input, message } from "antd";
import { authForgotPassword, authLogin } from "@services/auth.service";
import { getValue } from "@utils/lodash";
import LoginLayout from "@layouts/LoginLayout";
import { useNavigate } from "react-router-dom";
type FieldType = {
  email?: string;
  password?: string;
};

const ForgotPasswordPage: React.FC = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("Success:", values);
    try {
      setSubmitLoading(true);
      const resp = await authForgotPassword({
        email: values.email,
      });
      if (resp) {
        message.success(getValue(resp, `messsage`, "Email sent successfully"));
        navigate(`/login`);
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const [position, setPosition] = useState<"start" | "end">("end");
  const CustomTitle = ({ title }: { title: string }) => (
    <div className="d-flex justify-content-center align-items-center">
      <img src={"/logo-new.svg"} />
      <h6 className="header_text__18 mt-2">{title}</h6>
    </div>
  );
  return (
    <LoginLayout>
      <div className="login_container">
        <Col md={8}>
          <Card
            bordered={true}
            style={{ boxShadow: "#e3efff 0px 0px 0px 1px" }}
            title={<CustomTitle title="Password Reset" />}
          >
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              className="w-100"
            >
              <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  placeholder="Enter your email address"
                  style={{ height: "44px", border: "1px solid #cbdcf2" }}
                />
              </Form.Item>

              <Form.Item>
                <div className="d-flex justify-content-between align-items-center">
                  {submitLoading ? (
                    <Button
                      type="primary"
                      loading
                      iconPosition={position}
                      size="large"
                      style={{ background: "#38629A" }}
                    >
                      Get Reset Link
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      size="large"
                      style={{ background: "#38629A" }}
                    >
                      Get Reset Link
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Form>
            <Divider>
              <p className="small_text__14 d-flex mt-3">
                Back to{" "}
                <span
                  className="mx-2 color_secondary_main small_text__14 cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>{" "}
                ?
              </p>
            </Divider>
          </Card>
        </Col>
      </div>
    </LoginLayout>
  );
};

export default ForgotPasswordPage;
